var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("page-benefit page-benefit-" + (_vm.benefit.display_code))},[_c('div',{staticClass:"page-benefit-item"},[_c('div',{staticClass:"page-benefit-name"},[_vm._v(" "+_vm._s(_vm.benefit.name)+" ")]),_c('div',{staticClass:"page-benefit-desc"}),_c('div',{staticClass:"page-benefit-list"},_vm._l((_vm.benefit.list),function(spu,index){return _c('div',{key:index,staticClass:"page-benefit-spu",on:{"click":function($event){return _vm.jump(spu.benefit, spu.item_code)}}},[_c('div',{staticClass:"page-benefit-spu-inner"},[_c('div',{staticClass:"page-benefit-spu-info"},[_c('van-image',{staticClass:"spu-image",attrs:{"lazy-load":"","fit":"cover","src":spu.display_img_url}}),(_vm.benefit.display_code === 'life')?_c('div',{staticClass:"page-benefit-spu-desc"},[_vm._v(" "+_vm._s(spu.desc)),_c('span',[_vm._v(_vm._s(spu.lowest_face_value)+"元")])]):_c('div',{staticClass:"page-benefit-spu-desc"},[_vm._v(" "+_vm._s(spu.desc)+" ")]),(spu.selling_points)?_c('div',{staticClass:"page-benefit-spu-sell"},[_vm._v(" "+_vm._s(spu.selling_points)+" ")]):_vm._e(),(_vm.benefit.display_code === 'life')?_c('div',{staticClass:"page-benefit-spu-face-value"},[_c('span',[_vm._v("¥")]),_vm._v(_vm._s(spu.lowest_face_value * _vm.getCoundFromSellpoint(spu.selling_points))+" ")]):_c('div',{staticClass:"page-benefit-spu-face-value"},[_c('span',[_vm._v("¥")]),_vm._v(" "+_vm._s(spu.lowest_face_value)+" ")])],1),(_vm.benefit.display_code === 'life')?_c('van-button',{directives:[{name:"ans",rawName:"v-ans",value:({
              event_id: 'benefit_list_click',
              spu_id: spu.id,
              spu_name: spu.desc,
            }),expression:"{\n              event_id: 'benefit_list_click',\n              spu_id: spu.id,\n              spu_name: spu.desc,\n            }"}],staticClass:"page-benefit-spu-btn",attrs:{"type":"info","block":"","size":"small","disabled":!spu.benefit.amount,"round":""}},[_vm._v(_vm._s(_vm.getBenefitText(spu.benefit)))]):_vm._e()],1)])}),0),_c('div',{staticClass:"page-benefit-group-box"},[(_vm.benefit.display_code === 'group')?_c('van-button',{directives:[{name:"ans",rawName:"v-ans",value:({
          event_id: 'benefit_list_click',
          spu_id: _vm.benefit.list[0].id,
          spu_name: _vm.benefit.list[0].desc,
        }),expression:"{\n          event_id: 'benefit_list_click',\n          spu_id: benefit.list[0].id,\n          spu_name: benefit.list[0].desc,\n        }"}],staticClass:"page-benefit-group-btn",attrs:{"type":"info","size":"small","disabled":!_vm.benefit.list[0].benefit.amount,"round":""},on:{"click":function($event){return _vm.jump(_vm.benefit.list[0].benefit)}}},[_vm._v(_vm._s(_vm.getBenefitText(_vm.benefit.list[0].benefit)))]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }