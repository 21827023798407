export const ORDER_STATUS = {
  success: "已成功",
  failed: "已失败",
  confirming: "已支付，确认中",
  unpaid: "待支付",
  canceled: "已取消",
  refund: "已退款"
};
export const ORDER_DETAIL_STATUS = {
  success: "已成功",
  failed: "已失败",
  confirming: "确认中",
  unpaid: "待支付",
  canceled: "已取消",
  refund: "已退款",
  paid: "已支付"
};

export const ACTIVITY_ENUM = {
  end: {
    activityText: "本场已结束",
    btnName: "已结束",
    detailText: "已结束",
    statusName: "已结束",
    class: "end",
    text: "END",
    typeText: "下次再来",
    actionClass: "disable"
  },
  start: {
    activityText: "离本场结束",
    btnName: "立抢",
    detailText: "距离结束",
    statusName: "抢购中",
    class: "start",
    text: "抢",
    typeText: "秒杀进行中",
    actionClass: ""
  },
  unstart: {
    activityText: "离本场开始",
    detailText: "距离开始",
    btnName: "开抢",
    statusName: "即将开始",
    class: "unstart",
    text: "预",
    typeText: "秒杀开始前",
    actionClass: ""
  }
};
