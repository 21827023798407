<template>
  <main>
    <div
      :class="{
        'order-card coupon-code': true,
        'coupon-code-flex': payInfo.coupon_code && payInfo.secret_code,
      }"
    >
      <h3 class="coupon-name">{{ payInfo.item_name }}</h3>
      <div class="code-item" v-if="showLinkCard">
        <van-button
          @click="jumpLink"
          size="mini"
          round
          block
          :disabled="!available"
          class="copy"
          v-ans="{
            event_id: 'coupon_code_use_button',
            namespace,
          }"
          >{{ available ? "立即使用" : "已过期" }}</van-button
        >
      </div>
      <div class="code-item" v-if="payInfo.coupon_code && !showLinkCard">
        <p class="coupon-text">
          {{ available ? payInfo.coupon_code : "已过期" }}
        </p>
        <van-button
          v-if="available"
          size="mini"
          round
          block
          class="copy"
          v-clipboard:copy="payInfo.coupon_code"
          v-clipboard:success="copySuccess"
          v-clipboard:error="copyError"
          >复制券码</van-button
        >
      </div>

      <div class="code-item" v-if="payInfo.secret_code">
        <p class="coupon-text">
          {{ available ? payInfo.secret_code : "已过期" }}
        </p>
        <van-button
          v-if="available"
          block
          round
          class="copy"
          v-clipboard:copy="payInfo.secret_code"
          v-clipboard:success="copySuccess"
          v-clipboard:error="copyError"
          >复制密码</van-button
        >
      </div>
    </div>
  </main>
</template>

<script>
import { mapState } from "vuex";
import copy from "copy-to-clipboard";

export default {
  components: {},
  props: ["payInfo", "itemInfo"],
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapState(["isMiniProgram", "namespace"]),
    showLinkCard() {
      return /^(link)$/.test(this.payInfo.coupon_format);
    },
    available() {
      return this.payInfo.coupon_enable;
    },
  },
  methods: {
    close() {
      this.show = false;
    },
    copySuccess() {
      this.$toast("复制成功");
      if (this.itemInfo.activation_page) {
        window.location.href = this.itemInfo.activation_page;
      }
    },
    copyError() {
      this.$toast("复制失败，请手动复制");
    },
    jumpLink() {
      // 复制券码，提示用户通过外部浏览器打开
      if (this.isMiniProgram) {
        copy(this.payInfo.coupon_code);
        this.$toast("使用链接已复制，请打开外部浏览器使用");
        return;
      }
      window.location.href = this.payInfo.coupon_code;
    },
  },
};
</script>

<style lang="less" scoped>
.coupon-link {
  padding-top: 10px;
  padding-bottom: 16px;
  background: linear-gradient(90deg, #feec9d 0%, #ffc984 100%);
  text-align: center;
}
.coupon-tips {
  line-height: 18px;
  color: @text-gray2-color;
}
.coupon-title {
  font-size: 16px;
  padding: 14px 0;
}
.order-card.coupon-code {
  background: #fff3da;
  border-radius: 10px;
  border: 1px solid #ff9000;
  padding: 20px;
  text-align: center;
  margin: 40px 15px 0;
}
.coupon-text {
  line-height: 22px;
  font-size: 16px;
  margin-bottom: 16px;
}
.coupon-name {
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 15px;
}
.code-item {
  & + .code-item {
    margin-top: 16px;
  }
}
.copy {
  height: 45px;
  line-height: 45px;
  background-color: #1a1a1a;
  font-size: 18px;
  color: #fff3da;
}
.qrcode-image,
.qrcode-title {
  margin-bottom: 20px;
  margin-left: -10px;
  margin-right: -10px;
  text-align: center;
}
.coupon-button {
  padding: 0 20px;
}

.coupon-code-flex {
  .code-item {
    display: flex;
    align-items: center;
    p {
      word-break: break-all;
    }
    .coupon-text {
      margin-bottom: 0;
      flex: 1;
      text-align: left;
      word-break: break-all;
    }
    .copy {
      font-size: 12px;
      flex-basis: 90px;
      height: 30px;
      margin-left: 10px;
      flex-shrink: 0;
    }
  }
}
</style>