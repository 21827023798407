<template>
  <div class="warp">
    <Header />
    <component
      :is="computedCom"
      :couponValue="couponValue"
      :beginTime="beginTime"
      :expiredTime="expiredTime"
      :time="time"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  onMounted,
  ref,
  onUnmounted,
} from "@vue/composition-api";
import dayjs from "dayjs";
import Vue from "vue";

import { yueTuTravelCoupon } from "@/apis";
import { useRoute, useRouter } from "@/components/use/base";

import AirportVip from "./component/AirportVip.vue";
import Header from "./component/Header.vue";
import CommonVip from "./component/CommonVip.vue";

const vm = Vue.prototype;

export default defineComponent({
  components: {
    Header,
    CommonVip,
    AirportVip,
  },
  setup() {
    const beginTime = ref("");
    const expiredTime = ref("");
    const couponValue = ref({});
    const time = ref(60);
    const timer = ref(null);
    const route = useRoute();
    const router = useRouter();

    const initCouponValue = async () => {
      const orderNumber = route.query.order_number;
      const { data } = await yueTuTravelCoupon({ order_number: orderNumber });
      if (dayjs().isAfter(dayjs(data.expired_time))) {
        vm.$toast("权益已过期，即将返回订单页面");
        setTimeout(() => {
          router.replace("/order");
        }, 3000);
      }
      beginTime.value = dayjs(data.begin_time).format("YYYY-MM-DD");
      expiredTime.value = dayjs(data.expired_time).format("YYYY-MM-DD");
      couponValue.value = data;
    };

    const askCouponValue = async () => {
      if (time.value <= 0) {
        initCouponValue();
        time.value = 60;
      } else {
        time.value = --time.value;
      }
    };

    onMounted(() => {
      initCouponValue();
      timer.value = setInterval(() => {
        askCouponValue();
      }, 1000);
    });

    const computedCom = computed(() => {
      return {
        HSR: "CommonVip",
        airport: "AirportVip",
        check: "CommonVip",
        overseas_airport: "AirportVip",
      }[couponValue.value?.sign];
    });

    onUnmounted(() => {
      clearInterval(timer.value);
    });

    return {
      computedCom,
      couponValue,
      beginTime,
      expiredTime,
      time,
    };
  },
});
</script>


<style lang="less" scoped>
.warp {
  background: #fff;
  min-height: 100vh;
}
</style>