<template v-if="loading">
  <visitor
    v-if="visitor"
    :fetchActivityInfo="fetchActivityInfo"
    :activityInfo="activityInfo"
    :prizeRecord="prizeRecord"
  />
  <new
    v-else
    :activityName="activityName"
    :fetchActivityInfo="fetchActivityInfo"
    :activityInfo="activityInfo"
    :prizeRecord="prizeRecord"
  ></new>
</template>

<script>
import { getActivityInfo } from "@/apis";
import { promiseSuccess, storage } from "@/utils";
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";
import Visitor from "./visitor";
import New from "./new";
import { getAtivityRecord, getUserDrawAppoint } from "@/apis/luckdraw";
import { useRoute } from "@/components/use/base";

export default defineComponent({
  components: {
    New,
    Visitor,
  },
  setup() {
    const visitor = ref(false);
    const loading = ref(true);
    const activityInfo = reactive({ special_info: {}, keepAcitvityTimes: 0 });
    const prizeRecord = ref([]);
    const route = useRoute();
    const activityName =
      route.query.activity_name || `${storage.getItem("namespace")}_blind_box`;

    const fetchActivityInfo = async () => {
      const res = await getActivityInfo({ activity_name: activityName });
      await promiseSuccess(fetchPrizeRecord)(
        res.data.special_info.session_name
      );
      if (res.data.special_info.member_open_activity) {
        const appoint = await getUserDrawAppoint({
          activity_name: activityName,
        });
        res.data.keepAcitvityTimes = appoint.data.number;
      }
      Object.assign(activityInfo, res.data);
      if (
        !res.data.special_info.is_member &&
        res.data.special_info.is_visitor
      ) {
        visitor.value = true;
      }
    };

    const fetchPrizeRecord = async (session_name) => {
      const res = await getAtivityRecord({
        activity_name: activityName,
        session_name,
      });
      prizeRecord.value = res.data.filter((item) => item.extra_info.operation);
    };
    onMounted(async () => {
      await Promise.all([fetchActivityInfo()]);
      loading.value = false;
    });
    return {
      fetchActivityInfo,
      activityInfo,
      activityName,
      prizeRecord,
      visitor,
      loading,
    };
  },
});
</script>
