import Store from '@/store'
import { storage } from '.';


export default {
  init: function (data) {
  },
  alias: function (data) {
    const { aplus_queue } = window;
    aplus_queue.push({
      action: "aplus.setMetaInfo",
      arguments: ["_user_id", data]
    });
  },
  profileSetOnce: function (data) {
  },
  pageView: function (name, props, callback) {
    try {
      let page_name = name;
      const namespace = storage.getItem('namespace');
      const source = storage.getItem('source');
      const { aplus_queue } = window;
      let options = {
        //必传参数
        ts: new Date().getTime(),
        path: location.href,
        page_name,
        namespace,
        source,
        ...props
      };
      Store.state?.userInfo?.id && (options["user_id"] = Store.state.userInfo.id);
      aplus_queue.push({
        action: 'aplus.sendPV',
        arguments: [{
          is_auto: false
        }, options]
      });
    } catch (error) {
      console.log('err：调用quick tracking统计页面失败')
    }
  },
  track: function (eventName, eventInfo = {}, callback) {
    let event_name = eventName;
    let event_type = 'CLK';
    const namespace = storage.getItem('namespace');
    try {
      const { aplus_queue } = window;
      let options = {
        namespace,
        ...eventInfo,
      };
      Store.state?.userInfo?.id && (options["user_id"] = Store.state.userInfo.id);
      aplus_queue.push({
        action: 'aplus.record',
        arguments: [event_name, event_type, options]
      });
    } catch (error) {
      console.log('err：调用quick tracking统计事件失败')
    }
  },
  profileSet: function (data) {
    const { aplus_queue } = window;
    aplus_queue.push({
      'action': 'aplus.record',
      'arguments': ['$$_user_profile', 'OTHER', {
        ...data
      }]
    });
  }
}