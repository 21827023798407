<template>
  <div class="active-wrap">
    <div class="btn" @click="handleDraw">免费领取</div>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

import { useRouter } from "@/components/use/base";

export default defineComponent({
  props: {
    benefit: {
      default: {},
      type: Object,
    },
  },
  setup({ benefit }) {
    const router = useRouter();

    const handleDraw = () => {
      const benefit_type = benefit.list?.[0]?.product_code;
      // if (benefit.list[0].benefit.amount === 0) return;
      router.push(`/benefitDetail?benefit_type=${benefit_type}`);
    };

    return {
      handleDraw,
    };
  },
});
</script>

<style lang="less" scoped>
.active-wrap {
  background: url("https://common-h5-dev.xiangshuheika.com/static/upload/20240620dff586c52d9b4.png")
    no-repeat top/100%;
  height: 408px;
  margin: 0 auto;
  position: relative;
  .btn {
    width: 106px;
    height: 35px;
    background: linear-gradient(#ff9c5e 0%, #ff341b 100%);
    border-radius: 18px;
    text-align: center;
    font-size: 14px;
    color: #ffffff;
    line-height: 35px;
    position: absolute;
    left: 123px;
    top: 300px;
  }
}
</style>
