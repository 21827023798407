<template>
  <div class="wrap">
    <div class="lang-switch">
      <div
        class="lang"
        :class="lang === 'en' && 'select-lang'"
        @click="changeLang('en')"
      >
        ENG
      </div>
      <div
        class="lang"
        :class="lang === 'cn' && 'select-lang'"
        @click="changeLang('cn')"
      >
        中文
      </div>
    </div>
    <div class="title">{{ langConfig.title[lang] }}</div>
    <div class="desc">{{ langConfig.desc[lang] }}</div>
    <QrcodeVue class="qrcode" :value="couponValue.qr_code" size="162" />
    <div>
      <img
        src="https://common-h5-dev.xiangshuheika.com/static/upload/2024071812e3590a732c3.png"
        class="logo"
      />
    </div>
    <div class="time" v-if="lang === 'cn'">二维码将在{{ time }}秒后刷新</div>
    <div class="time" v-else>QR code refreshes in {{ time }} secs</div>
    <div class="sub-title">{{ langConfig.name[lang] }}</div>
    <div class="sub-desc flex-box">
      {{ userName }}
      <van-icon :name="showIcon" class="icon" @click="handleMosaic" />
    </div>
    <div class="sub-title">{{ langConfig.code[lang] }}</div>
    <div class="sub-desc">{{ couponValue.qr_code }}</div>
    <div class="sub-title">{{ langConfig.start[lang] }}</div>
    <div class="sub-desc">{{ startTime }}</div>
    <div class="sub-title">{{ langConfig.end[lang] }}</div>
    <div class="sub-desc">{{ endTime }}</div>
    <div class="desc-wrap">
      <div>{{ langConfig.conditions[lang] }}</div>
      <p>{{ langConfig.list1[lang] }}</p>
      <p>{{ langConfig.list2[lang] }}</p>
      <p>{{ langConfig.list3[lang] }}</p>
      <p>{{ langConfig.list4[lang] }}</p>
      <p>{{ langConfig.list5[lang] }}</p>
      <p>{{ langConfig.list6[lang] }}</p>
      <p class="p-link" @click="showRule">{{ langConfig.list7[lang] }}</p>
    </div>
    <div class="footer-wrap">
      <div class="footer" @click="showStore">查看可用网点</div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "@vue/composition-api";
import QrcodeVue from "qrcode.vue";
import Vue from "vue";

import { maskName } from "@/utils/index";

import { LANG_CONFIG } from "./lang";
import * as dayjs from "dayjs";

const vm = Vue.prototype;

export default defineComponent({
  props: ["couponValue", "beginTime", "expiredTime", "time"],
  components: {
    QrcodeVue,
  },
  setup({ couponValue, beginTime, expiredTime }) {
    const lang = ref("en");
    const langConfig = ref({ ...LANG_CONFIG });

    const mosaic = ref(true);

    const showIcon = computed(() => {
      return !mosaic.value ? "eye-o" : "closed-eye";
    });

    const userName = computed(() => {
      const name =
        lang.value === "en" ? couponValue.en_name : couponValue.user_name;
      return mosaic.value ? maskName(name) : name.toUpperCase();
    });

    const handleMosaic = () => {
      mosaic.value = !mosaic.value;
    };

    const startTime = computed(() => {
      return lang.value === "en"
        ? dayjs(beginTime).format("D MMMM YYYY")
        : beginTime;
    });

    const endTime = computed(() => {
      return lang.value === "en"
        ? dayjs(expiredTime).format("D MMMM YYYY")
        : expiredTime;
    });

    const changeLang = (value) => {
      lang.value = value;
    };

    const showRule = () => {
      const url = JSON.parse(vm.$getConfigValue("TRAVEL_YUETU_BENEFIT"))[
        "rule"
      ];
      window.open(url);
    };

    const showStore = () => {
      const url = JSON.parse(vm.$getConfigValue("TRAVEL_YUETU_BENEFIT"))[
        couponValue.sign
      ];
      window.open(url);
    };

    return {
      lang,
      langConfig,
      showIcon,
      userName,
      endTime,
      startTime,
      changeLang,
      handleMosaic,
      showStore,
      showRule,
    };
  },
});
</script>

<style scoped lang='less'>
.wrap {
  font-weight: 400;
  text-align: center;
  padding-bottom: 90px;
}
.lang-switch {
  display: flex;
  justify-content: flex-end;
  margin-right: 15px;
  .lang {
    width: 52px;
    font-size: 14px;
    color: #bababa;
    line-height: 24px;
    text-align: center;
    border-bottom: 1px solid #bababa;

    &.select-lang {
      color: #827127;
      border-bottom: 1px solid #827127;
    }
  }
}
.title {
  font-weight: 400;
  font-size: 26px;
  color: #827127;
  margin-top: 18px;
}
.desc {
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  text-align: center;
  margin-top: 8px;
}
.qrcode {
  margin: 0 auto;
  margin-top: 24px;
  height: 162px;
  width: 162px;
}
.logo {
  margin-top: 24px;
  height: 38px;
  width: 135px;
}
.time {
  margin-top: 25px;
  font-weight: 400;
  font-size: 14px;
  color: #827127;
  line-height: 24px;
  margin-bottom: 20px;
}
.sub-title {
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  line-height: 20px;
}
.sub-desc {
  margin-top: 2px;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  line-height: 20px;
  margin-bottom: 10px;

  &.flex-box {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    .icon {
      margin-left: 8px;
    }
  }
}
.desc-wrap {
  font-family: PingFang SC, PingFang SC;
  margin-top: 20px;
  border-top: 1px solid #827127;
  padding: 20px 12px 0 12px;
  font-weight: 400;
  word-break: break-all;
  text-align: justify;
  > div {
    font-weight: 500;
    font-size: 17px;
    color: #827127;
    line-height: 20px;
    text-align: left;
    margin-bottom: 10px;
  }
  p {
    text-align: justify;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    line-height: 20px;
  }
  .p-link {
    text-decoration: underline;
  }
}

.footer-wrap {
  position: fixed;
  bottom: 0px;
  width: 100%;
  background: #fff;
}
.footer {
  width: 335px;
  height: 50px;
  background: #dcc8a3;
  border-radius: 8px 8px 8px 8px;
  margin: 15px auto 24px auto;

  font-weight: 500;
  font-size: 16px;
  color: #272727;
  line-height: 50px;
}
</style>