<template>
  <div :class="`page-benefit page-benefit-${benefit.display_code}`">
    <div class="page-benefit-item">
      <div class="page-benefit-name">
        {{ benefit.name }}
      </div>
      <div class="page-benefit-desc"></div>
      <div class="page-benefit-list">
        <div
          v-for="(spu, index) in benefit.list"
          :key="index"
          @click="jump(spu.benefit, spu.item_code)"
          class="page-benefit-spu"
        >
          <div class="page-benefit-spu-inner">
            <div class="page-benefit-spu-info">
              <van-image
                class="spu-image"
                lazy-load
                fit="cover"
                :src="spu.display_img_url"
              />
              <div
                class="page-benefit-spu-desc"
                v-if="benefit.display_code === 'life'"
              >
                {{ spu.desc }}<span>{{ spu.lowest_face_value }}元</span>
              </div>
              <div class="page-benefit-spu-desc" v-else>
                {{ spu.desc }}
              </div>
              <div class="page-benefit-spu-sell" v-if="spu.selling_points">
                {{ spu.selling_points }}
              </div>
              <div
                class="page-benefit-spu-face-value"
                v-if="benefit.display_code === 'life'"
              >
                <span>¥</span
                >{{
                  spu.lowest_face_value *
                  getCoundFromSellpoint(spu.selling_points)
                }}
              </div>
              <div class="page-benefit-spu-face-value" v-else>
                <span>¥</span>
                {{ spu.lowest_face_value }}
              </div>
            </div>
            <van-button
              v-if="benefit.display_code === 'life'"
              class="page-benefit-spu-btn"
              type="info"
              block
              size="small"
              :disabled="!spu.benefit.amount"
              v-ans="{
                event_id: 'benefit_list_click',
                spu_id: spu.id,
                spu_name: spu.desc,
              }"
              round
              >{{ getBenefitText(spu.benefit) }}</van-button
            >
          </div>
        </div>
      </div>
      <div class="page-benefit-group-box">
        <van-button
          v-if="benefit.display_code === 'group'"
          class="page-benefit-group-btn"
          type="info"
          size="small"
          @click="jump(benefit.list[0].benefit)"
          :disabled="!benefit.list[0].benefit.amount"
          v-ans="{
            event_id: 'benefit_list_click',
            spu_id: benefit.list[0].id,
            spu_name: benefit.list[0].desc,
          }"
          round
          >{{ getBenefitText(benefit.list[0].benefit) }}</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import pickBy from "lodash.pickby";

import { getCoundFromSellpoint } from "@/utils";
import { useRouter } from "@/components/use/base";

export default defineComponent({
  props: {
    benefit: {
      default: {},
      type: Object,
    },
  },
  setup() {
    const router = useRouter();

    const getBenefitText = (benefit) => {
      if (benefit.amount > 0) {
        return "免费领取";
      } else {
        if (benefit.amount_reserved > 0) {
          return "本月已领取";
        }
        return "已领完";
      }
    };

    const jump = (spu, item_code = "") => {
      const query = pickBy({
        benefit_type: spu.type,
        detail_type: item_code,
      });
      router.push({
        path: "/benefitDetail",
        query,
      });
    };

    return {
      getBenefitText,
      jump,
      getCoundFromSellpoint,
    };
  },
});
</script>

<style lang="less" scoped>
.page-benefit-name {
  font-size: 18px;
  color: #333;
  line-height: 25px;
  font-weight: bold;
  margin-bottom: 9px;
  text-align: center;
}

.page-benefit-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}

.page-benefit-spu {
  position: relative;
  width: 50%;
  box-sizing: border-box;
  padding: 0 5px;
  margin-bottom: 16px;
}

.spu-image {
  height: 96px;
  width: auto;
}

.page-benefit-spu {
  position: relative;
  width: 50%;
  box-sizing: border-box;
  padding: 0 5px;
  margin-bottom: 16px;
}

.page-benefit-spu-desc {
  margin-top: 10px;
  font-size: 15px;
  line-height: 21px;
  .font-family("PingFangSC-Semibold");
}

.page-benefit-spu-sell {
  margin-top: 4px;
  line-height: 17px;
}

.page-benefit-spu-btn {
  margin-top: 10px;
  &[disabled] {
    background: #d9d9d9;
  }
}

.page-benefit-group-box {
  padding-bottom: 14px;
  .page-benefit-group-btn {
    &[disabled] {
      background: #999;
    }
    width: 180px;
    display: block;
    margin: 0 auto 0;
  }
}

.page-benefit-group {
  .page-benefit-spu-desc {
    margin-top: 0;
    position: absolute;
    left: 12px;
    top: 10px;
    font-size: 14px;
    color: #fff;
  }
  .page-benefit-spu-face-value {
    position: absolute;
    left: 10px;
    top: 35px;
    color: #fff;
    opacity: 0.7;
  }
}

.page-benefit-life {
  .page-benefit-spu-face-value {
    top: 10px;
    position: absolute;
    left: 10px;
    color: #fff;
    font-size: 18px;
    span {
      font-size: 12px;
      margin-right: 2px;
    }
  }
}

.page-benefit-spu-btn {
  width: 88px;
  height: 28px;
  line-height: 28px;
  color: #fff;
  background: linear-gradient(360deg, #ff3922 0%, #fa6400 100%);
}
</style>
