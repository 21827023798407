<template>
  <div v-if="!loading">
    <div v-if="!activityList.length" class="activity-empty">
      <van-image
        class="empty-image"
        fit="cover"
        lazy-load
        :src="require('../../assets/images/activity/empty3.png')"
      />
      <div class="empty-text">
        <p>
          <span>{{ detail.activity_preface || "每周定期" }}</span> 秒杀活动开启
        </p>
        <p>请届时再来，你可先返回使用其它会员权益</p>
      </div>
    </div>
    <main v-else class="seckill">
      <div class="activity-bg">
        <van-image
          fit="cover"
          class="activity-bg-image"
          lazy-load
          :src="
            activity.header_image_url ||
            'https://cdn.xiangshuheika.com/static/activity/header_image_url.png'
          "
        />

        <div class="activity-rule" @click="showRule">规则</div>
      </div>
      <van-tabs v-model="active" swipeable animated :line-width="60">
        <van-tab v-for="activity in activityList" :key="activity.id">
          <template #title>
            <div class="activity-time">
              <div class="day">{{ day(activity.start_time) }}</div>
              <div class="time">{{ time(activity.start_time) }}</div>
            </div>
            <div class="activity-status">
              <div class="tip" :class="statueClassEnum[activity.status].class">
                {{ statueClassEnum[activity.status].text }}
              </div>
              {{ statusEnum[activity.status].statusName }}
            </div>
          </template>
          <div class="products">
            <div class="activity-count-down" v-if="activity.status !== 'end'">
              <div class="activity-count-title">{{ activityName }}</div>
              <van-count-down
                class="count-down"
                @finish="finish"
                :time="getCountTime"
              >
                <template #default="timeData">
                  <template v-if="timeData.days > 0">
                    <span class="block">{{ timeData.days }}天</span>
                    <span class="colon">:</span>
                  </template>
                  <span class="block">{{ timeData.hours | zero }}</span>
                  <span class="colon">:</span>
                  <span class="block">{{ timeData.minutes | zero }}</span>
                  <span class="colon">:</span>
                  <span class="block">{{ timeData.seconds | zero }}</span>
                </template>
              </van-count-down>
            </div>
            <!-- 卡片 -->
            <div
              class="product-item"
              v-for="item in activity.sku_list"
              @click="
                toDetail(
                  item,
                  activity.start_time,
                  activity.start_time,
                  activity.status
                )
              "
              :key="item.item_code"
              v-ans="{
                event_id: 'seckill_jump_detail_click',
                namespace,
                item_code: item.item_code,
                activity_name: activityQueryName,
                start_time: activity.start_time,
                activity_status: activity.status,
              }"
            >
              <div class="product-img-wrap">
                <van-image fit="cover" lazy-load :src="item.image" />
              </div>

              <div class="product-content">
                <div class="product-title">
                  <p class="product-name">{{ item.title }}</p>
                </div>
                <div
                  class="product-context"
                  :class="getComputedStatus(item, 'actionClass')"
                >
                  <div class="spike">
                    <div class="spike-text">{{ item.obtain_tips }}¥</div>
                    <div class="spike-price">{{ item.activity_price }}</div>
                  </div>
                  <div class="price-wrap">
                    <div class="face-value" :class="getMinFontSize(item)">
                      <span>¥</span>{{ getValue(item) }}
                    </div>
                    <div class="price-text">已补</div>
                  </div>
                  <div class="action-wrap">
                    <div class="head">
                      <img
                        src="@/assets/images/seckill-run-icon.png"
                        :class="computedActionIcon(item)"
                      />
                      <span :class="computedActionIcon(item)"
                        >{{ getStartTime }}
                      </span>
                    </div>
                    <div class="text">
                      {{ getBtnText(item) }}
                      <img
                        src="@/assets/images/left-white-arrow.png"
                        :class="computedActionIcon(item)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </main>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { getSeckillInfo, querySeckillStock } from "@/apis/home";
import { ACTIVITY_ENUM } from "@/constants";
import { mapState } from "vuex";
import { storage } from "@/utils";
import Decimal from "decimal.js";

export default {
  data() {
    return {
      active: 0,
      statusEnum: ACTIVITY_ENUM,
      activityList: [],
      loading: false,
      detail: {},
      statueClassEnum: ACTIVITY_ENUM,
    };
  },
  computed: {
    // 获取倒计时
    ...mapState(["userInfo", "namespace"]),
    getCountTime() {
      let time;
      if (this.activity.status === "start") {
        time = this.activityList[this.active].end_time;
      } else {
        time = this.activityList[this.active].start_time;
      }
      const cur = dayjs().valueOf();
      return dayjs(time).valueOf() - cur;
    },
    activity() {
      return this.activityList[this.active];
    },
    activityName() {
      return ACTIVITY_ENUM[this.activity.status].activityText;
    },
    activityQueryName() {
      const namespace = storage.getItem("namespace");
      // 判断新人秒杀活动
      if (this.$route.query.activitySource === "new_user") {
        return namespace + "_seckill_new_user";
      }
      return namespace + "_seckill";
    },
    getValue() {
      return function (item) {
        return new Decimal(item.face_value).minus(item.activity_price);
      };
    },
    getMinFontSize() {
      return function (item) {
        if (
          new Decimal(item.face_value).minus(item.activity_price).toString()
            .length >= 5
        )
          return "mini-font-size";
        return "";
      };
    },
    day() {
      return function (date) {
        return this.formatDate(date).split(" ")[0];
      };
    },
    time() {
      return function (date) {
        return this.formatDate(date).split(" ")[1];
      };
    },
    getStartTime() {
      return dayjs(this.activity.start_time).format("HH:mm");
    },
    getComputedStatus() {
      return function (item = {}, type) {
        if (item?.stock?.user_daily_stock === 0 || this.getWidth(item) === 100)
          return this.statueClassEnum["end"][type];
        return this.statueClassEnum[this.activity.status][type];
      };
    },
    computedActionIcon() {
      return function (item) {
        if (item.stock?.user_daily_stock === 0 || this.getWidth(item) == 100)
          return "disable";
        if (this.activity.status === "unstart") return "unstart";
        return "none";
      };
    },
  },
  async mounted() {
    this.loading = true;
    const loading = this.$loading();
    try {
      // 获取活动信息
      const res = await getSeckillInfo({
        activity_name: this.activityQueryName,
      });
      this.detail = res.data;
      // 只显示48h以内的活动
      this.activityList = res.data.session_info.slice(0, 3).map((item) => {
        item.status = this.getStatus(item.start_time, item.end_time);
        return item;
      });
      const idx = this.activityList.findIndex(
        (r) => r.status === "start" || r.status === "unstart"
      );
      this.active = idx > -1 ? idx : 0;
      if (
        this.activityList[this.active] &&
        this.activityList[this.active].status === "start"
      ) {
        await this.queryStock(this.active, true);
      }
      this.timer = setInterval(() => {
        this.changeStatus();
      }, 1000);
    } finally {
      this.loading = false;
      loading.clear();
    }
  },

  methods: {
    formatDate(date) {
      return dayjs(date).locale("zh").calendar(null, {
        lastWeek: "MM.DD HH:mm",
        lastDay: "[昨天] HH:mm",
        sameDay: "HH:mm",
        nextDay: "[明天] HH:mm",
        nextWeek: "ddd HH:mm",
        sameElse: "MM.DD HH:mm",
      });
    },
    getDisabled(item, activity) {
      return (
        this.getWidth(item) == 100 ||
        activity.status !== "start" ||
        item.stock?.user_daily_stock === 0
      );
    },
    getKillState(item) {
      if (item.stock?.user_daily_stock === 0) {
        return "isBuy";
      }
      if (this.getWidth(item) == 100) {
        return "sellOff";
      }
      return "";
    },

    getBtnText(item) {
      if (item.stock?.user_daily_stock === 0) {
        return "已抢购";
      }
      if (this.getWidth(item) == 100) {
        return "售罄";
      }
      if (this.activity.status === "unstart") {
        return "开抢";
      }
      return ACTIVITY_ENUM[this.activity.status].btnName;
    },
    changeStatus() {
      this.activityList = this.activityList.map((item) => {
        item.status = this.getStatus(item.start_time, item.end_time);
        return item;
      });
    },
    getDiscount({ activity_price, face_value }) {
      if (
        face_value === 0 ||
        activity_price === 0 ||
        face_value <= activity_price
      ) {
        return "";
      }
      const str = ((activity_price / face_value) * 10).toFixed(2);
      return Number(str.slice(0, str.length - 1));
    },
    getWidth({ stock }) {
      if (!stock || !Object.keys(stock).length || !stock.session_stocks) {
        return 0;
      }
      return Number(
        ((stock.session_used_stocks / stock.session_stocks) * 100).toFixed(0)
      );
    },
    getStatus(start_time, end_time) {
      let status;
      if (dayjs().isBefore(dayjs(start_time))) {
        status = "unstart";
      } else if (dayjs().isAfter(dayjs(end_time))) {
        status = "end";
      } else {
        status = "start";
      }
      return status;
    },
    finish() {
      if (this.getCountTime < 0) {
        return;
      }
      this.changeStatus();
    },
    showRule() {
      this.$alert({
        title: "活动规则",
        message: this.detail.rules,
        confirmButtonText: "我知道了",
        className: "seckill-modal-rule",
      });
    },
    // 查询库存
    async queryStock(active, isLoop) {
      clearTimeout(this.stockTimer);
      const item_codes = this.activity.sku_list
        .map((r) => r.item_code)
        .join("~");
      if (!item_codes || !item_codes.length) {
        return;
      }
      const res = await querySeckillStock({
        session_id: this.activity.start_time,
        activity_name: this.activityQueryName,
      });
      // 处理item_code只有一个导致后端返回数据格式不一致的问题
      let stockList = Object.entries(res.data).map((item) => ({
        item_code: item[0],
        data: { ...item[1], item_code: item[0] },
      }));
      if (active !== this.active) {
        return;
      }
      if (this.activity.status === "start") {
        this.activity.sku_list.forEach((item) => {
          item.stock =
            (stockList.find((r) => r.item_code === item.item_code) || {})
              .data || {};
        });
        if (isLoop) {
          this.stockTimer = setTimeout(() => {
            this.queryStock(active, isLoop);
          }, 4000);
        }
      }
    },
    async toDetail(item, startTime, session_name, status) {
      this.$router.push(
        `/mdetail?item_code=${item.item_code}&startTime=${startTime}&activityName=${this.activityQueryName}&session_id=${session_name}`
      );
    },
  },

  watch: {
    active(val) {
      const activity = this.activityList[val];
      clearTimeout(this.stockTimer);
      if (activity.status === "start") {
        // 轮询库存
        this.queryStock(val, true);
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearTimeout(this.stockTimer);
  },
};
</script>

<style lang="less" scoped>
.mini-font-size {
  font-size: 12px !important;
}
.seckill {
  /deep/ .van-tab__text--ellipsis {
    width: 100%;
    height: 100%;
    text-align: center;
    overflow: auto;
    -webkit-line-clamp: inherit;
  }
  /deep/ .van-tabs__wrap {
    border-radius: 16px 16px 0 0;
    background: #f5f5f5;
  }
  /deep/ .van-tabs__nav {
    background: #f5f5f5;
  }
  /deep/ .van-tab--active {
    background: #ffffff !important;
    position: relative;
    &::after {
      content: "";
      z-index: 1;
      display: block;
      position: absolute;
      bottom: 0px;
      right: -16px;
      width: 16px;
      height: 16px;
      background: radial-gradient(circle at 100% 0%, transparent 16px, #fff 0);
    }
    &::before {
      content: "";
      z-index: 1;
      display: block;
      position: absolute;
      bottom: 0px;
      left: -16px;
      width: 16px;
      height: 16px;
      background: radial-gradient(circle at 0% 0%, transparent 16px, #fff 0);
    }
  }
  /deep/ .van-tab {
    background: #f5f5f5;
    border-radius: 16px 16px 0 0 !important;
  }
  /deep/ .van-tab__pane {
    background: #fff;
    min-height: calc(100vh - 192px);
  }
}
.activity-bg {
  height: 153px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: -22px;
}
.activity-rule {
  position: absolute;
  right: 0;
  top: 0;
  padding: 3px 7px 3px 12px;
  border-radius: 100px 0px 0px 100px;
  background: rgba(248, 59, 17, 0.65);
  color: #fff;
  font-size: 12px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  line-height: 18px;
}

.activity-count-down {
  display: flex;
  align-items: center;
  padding: 15px 0 5px;
  position: relative;
  z-index: 100;
  margin: 0 auto;
  width: fit-content;
  margin-bottom: 21px;
  .block {
    padding: 1px 3px;
    background: #f5f5f5;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
    font-family: Arial, Arial;
    font-weight: 500;
    color: #ff5001;
  }
}

.activity-count-title {
  font-size: 16px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 22px;
  margin-right: 5px;
}
.count-down {
  border-radius: 9px;
  margin-left: 5px;
  color: #fff;
  box-sizing: border-box;
  display: flex;
}
.colon {
  font-size: 16px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #e0e0e0;
  margin: 0 4px;
}

.products {
  margin: 0 16px 20px;
}

.product-item {
  background: #ffffff;
  margin-bottom: 16px;
  height: 120px;
  box-sizing: border-box;
  display: flex;
  position: relative;
}
.product-img-wrap {
  width: 120px;
  height: 120px;
  background: #f5f5f5;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 16px;
}

.product-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-title {
  display: flex;
  justify-content: space-between;
  .product-name {
    font-size: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 22px;
  }

  .product-type {
    border-radius: 4px;
    padding: 5px 12px;
    font-size: 10px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    line-height: 14px;
    &.unstart {
      background: #e9f8eb;
      color: #16c075;
    }
    &.start {
      color: #ec3d5b;
      background: #fde0df;
    }
    &.end {
      color: #ec3d5b;
      background: #fde0df;
    }
  }
}

.product-context {
  height: 45px;
  background: #fe2200;
  border-radius: 8px;
  position: relative;
  display: flex;
  justify-content: space-between;

  &.disable {
    background: #bbbbbb;
    .price-wrap {
      background-image: url("../../assets/images/seckill-price-wrap-disable.png");
      color: #c6c8d1;
      background-repeat: no-repeat;
    }
  }

  .spike {
    display: flex;
    font-family: PingFang-SC, PingFang-SC;
    flex-wrap: nowrap;
    color: #ffffff;
    margin-top: 10px;

    .spike-text {
      font-size: 12px;
      font-weight: bold;
      line-height: 17px;
      margin-top: 10px;
      margin-left: 8px;
    }
    .spike-price {
      font-size: 24px;
      font-weight: 600;
      margin-left: 5px;
    }
  }
  .price-wrap {
    background-image: url("../../assets/images/seckill-price-wrap.png");
    height: 56px;
    width: 56px;
    background-size: contain;
    position: absolute;
    left: 115px;
    bottom: -5px;
    font-family: PingFangSC, PingFang SC;
    color: #b05229;

    .face-value {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      > span {
        font-size: 10px;
        margin-right: 3px;
      }
    }
    .price-text {
      font-weight: 400;
      margin-left: 12px;
      font-size: 12px;
    }
  }
  .action-wrap {
    font-size: 12px;
    font-family: HuXiaoBo, HuXiaoBo;
    font-weight: normal;
    color: #ffffff;
    line-height: 12px;
    margin-top: 8px;
    z-index: 2;
    .head {
      font-family: PingFangSC, PingFang SC;
      font-size: 12px;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 2px;
      line-height: 16px;
      height: 16px;
      font-weight: 600;
      > img {
        margin-right: 15px;
        width: 14px;
        height: 14px;
        &.disable {
          display: none;
        }
        &.unstart {
          display: none;
        }
      }
      > span {
        margin-right: 3px;
        &.none {
          display: none;
        }
        &.disable {
          display: none;
        }
      }
    }
    .text {
      margin-right: 9px;
      text-align: right;
      > img {
        height: 8px;
        width: 8px;
        &.disable {
          display: none;
        }
        &.unstart {
          display: none;
        }
      }
    }
  }
}

.activity-bg-image {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.activity-time {
  margin-top: 9px;
  line-height: 25px;
  font-size: 15px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  > .day {
    height: 18px;
    background: #ff5001;
    border-radius: 4px;
    padding: 0 5px;
    font-size: 14px;
    font-family: PingFang-SC, PingFang-SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 18px;
    margin-right: 6px;
  }
  > .time {
    font-size: 18px;
    font-family: Arial, Arial;
    font-weight: normal;
    color: #666666;
    line-height: 21px;
  }
}
.activity-status {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 14px;
  .tip {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    font-size: 12px;
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    color: #ffffff;
    line-height: 17px;
    text-align: center;
    margin-right: 8px;
    &.start {
      background: linear-gradient(180deg, #ff8802 0%, #ff5001 100%);
    }
    &.unstart {
      background: linear-gradient(180deg, #8fc490 0%, #35924b 100%);
    }
    &.end {
      font-size: 8px;
      background: linear-gradient(180deg, #ffffff 0%, #afafaf 100%);
    }
  }
  &.disabled {
    background-color: #999999;
  }
}
/deep/ .van-tabs__wrap {
  height: 60px;
}
/deep/ .van-tabs__line {
  display: none;
  height: 2px;
  background-color: #ff5001;
}
/deep/ .van-tab--active {
  font-weight: bold;
  .activity-status {
    color: #ff5001;
  }
}
</style>

<style lang="less">
.seckill-modal-rule {
  .van-dialog__content {
    padding-bottom: 20px;
  }
  .van-dialog__message {
    max-height: 180px;
    overflow: auto;
    text-align: left;
  }
}
.activity-empty {
  text-align: center;
}
.empty-image {
  width: 100px;
  margin: 123px auto 0;
}
.empty-text {
  font-size: 14px;
  color: #333;
  line-height: 28px;
  margin-top: 32px;
  span {
    color: #ff5001;
    font-weight: bold;
  }
}
</style>