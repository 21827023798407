var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.result)?_c('main',{staticClass:"pay-result"},[_c('div',{staticClass:"header",class:_vm.result !== 'success' && 'high'},[_c('svg-icon',{staticClass:"pay-result-icon",class:_vm.result !== 'success' && 'icon-not-success',attrs:{"iconClass":_vm.payResultIcon}}),_c('div',{staticClass:"pay-result-text",class:_vm.result !== 'success' && 'not-success-text'},[_vm._v(" "+_vm._s(_vm.payResultText)+" ")]),(_vm.result !== 'success')?_c('div',{staticClass:"pay-result-desc"},[_vm._v(" 支付遇到问题，请尝试重新支付 ")]):_vm._e()],1),(_vm.result === 'success')?_c('div',{staticClass:"price-wrap"},[_c('div',{staticClass:"price"},[_c('span',[_vm._v("¥")]),_vm._v(_vm._s(_vm.amount))]),_c('div',{staticClass:"pay-method"},[_vm._v(_vm._s(_vm.source))]),(!!_vm.discount || _vm.payInfo.coupon_real_price)?_c('div',{staticClass:"discount"},[_c('div',[_c('span',[_vm._v("原价")]),_c('span',{staticClass:"face-value"},[_vm._v("¥"+_vm._s(_vm.itemInfo.face_value || _vm.payInfo.total_origin_price)+" ")])]),(!!_vm.discount)?_c('div',[_c('span',[_vm._v("优惠")]),_c('span',[_vm._v("会员优惠¥"+_vm._s(_vm.discount))])]):_vm._e()]):_vm._e()]):_vm._e(),_c('div',{staticClass:"footer"},[(_vm.result === 'success')?[(_vm.category_type === 'vip')?_c('van-button',{directives:[{name:"ans",rawName:"v-ans",value:({
          event_id: 'pay_result_btn_click1',
        }),expression:"{\n          event_id: 'pay_result_btn_click1',\n        }"}],staticClass:"pay-result-btn no-border",class:_vm.payBtnColor,attrs:{"block":"","round":"","type":"info"},on:{"click":_vm.toHome}},[_vm._v(_vm._s(_vm.namespace === "pingan_gj" ? "查看会员卡" : "立即查看权益"))]):_c('van-button',{directives:[{name:"ans",rawName:"v-ans",value:({
          event_id: 'pay_result_btn_click1',
        }),expression:"{\n          event_id: 'pay_result_btn_click1',\n        }"}],staticClass:"pay-result-btn no-border",class:_vm.payBtnColor,attrs:{"block":"","type":"info","round":""},on:{"click":_vm.toOrder}},[_vm._v("立即查看权益")])]:(_vm.category_type === 'vip' && _vm.namespace === 'pingan_gj')?_c('van-button',{directives:[{name:"ans",rawName:"v-ans",value:({
        event_id: 'pay_result_btn_click2',
      }),expression:"{\n        event_id: 'pay_result_btn_click2',\n      }"}],staticClass:"pay-result-btn",attrs:{"block":"","round":"","type":"info"},on:{"click":_vm.toHome}},[_vm._v("立即返回")]):_vm._e(),(_vm.$getConfigValue('INDEX_URL') && _vm.namespace !== 'pingan_gj')?_c('van-button',{directives:[{name:"ans",rawName:"v-ans",value:({
        event_id: 'pay_result_btn_click2',
      }),expression:"{\n        event_id: 'pay_result_btn_click2',\n      }"}],staticClass:"pay-result-btn",attrs:{"block":"","round":"","plain":""},on:{"click":_vm.toHome}},[_vm._v("返回首页")]):_vm._e()],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }