<template>
  <div>
    <div class="draw-btn-wrap prize-list-btn">
      <van-button
        round
        block
        plain
        :class="{ 'draw-btn': true, [`draw-btn-${type}`]: true }"
        @click="show = true"
      >
        查看我的奖品
      </van-button>
    </div>
    <van-dialog
      v-model="showDialog"
      theme="confirm common-dialog-confirm"
      confirm-button-text="立即领取"
      cancel-button-text="取消"
      show-cancel-button
      @cancel="close"
      :before-close="beforeClose"
      @confirm="confirm"
    >
      <div class="account-doalog-main">
        <div class="title">请填写手机号，提交后充值到账</div>
        <van-field
          :disabled="disabled"
          class="field-input"
          v-model.trim="top_up_account"
          placeholder="请输入手机号"
        />
      </div>
    </van-dialog>
    <van-action-sheet
      v-model="show"
      duration="0.2"
      title="我的奖品"
      get-container="body"
    >
      <div class="luck-draw-record">
        <div
          v-for="item in prizeRecord"
          :key="item.t_created"
          class="draw-item"
        >
          <div class="draw-item-l">
            <van-image
              lazy-load
              :src="CDN + `${item.extra_info.item_code}.png`"
              alt=""
            />
          </div>
          <div class="draw-name">{{ item.extra_info.prize_name }}</div>
          <div
            class="draw-use-btn"
            v-if="item.status === 5"
            @click="toUse(item.extra_info)"
          >
            去使用
          </div>
          <div
            class="draw-use-btn"
            v-if="item.status === 7"
            @click="toGet(item)"
          >
            领取
          </div>
          <div class="draw-use-btn expired" v-if="item.status === 8">
            已失效
          </div>
        </div>
        <div v-if="!prizeRecord.length" class="draw-no-prize">
          还没有抽中奖品哦~
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { getItemInfo } from "@/apis/home";
import {
  handleActivityReward,
  handleKeepActivityReward,
} from "@/apis/luckdraw";
import { useStore } from "@/components/use/base";
import { computed, defineComponent, ref } from "@vue/composition-api";
import Vue from "vue";
import { CDN } from "../../config";
const vm = Vue.prototype;

export default defineComponent({
  props: {
    type: String,
    activityInfo: Object,
    prizeRecord: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const store = useStore();
    const show = ref(false);
    const sku = ref({});
    const temp = ref({});
    const top_up_account = ref(store.state.userInfo.user_phone || "");
    const showDialog = ref(false);

    const disabled = computed(() => !!store.state.userInfo.user_phone);
    const open = () => {
      show.value = true;
    };
    //去领奖 or 去使用
    const toUse = (item) => {
      if (
        item.operation === "use" ||
        (item.extra_info && item.extra_info.operation === "use")
      ) {
        vm.$jumpUrl(
          `/orderDetail?order_number=${
            item.order_number || item.extra_info.order_number
          }`
        );
      }
      if (
        item.operation === "buy" ||
        (item.extra_info && item.extra_info.operation === "buy")
      ) {
        vm.$jumpUrl(
          `/benefitDetail?benefit_type=${
            item.benefit_type || item.extra_info.benefit_type
          }`
        );
      }
    };
    const toGet = async (item) => {
      const toast = vm.$loading();
      const skuData = await getItemInfo({
        item_code: item.extra_info.item_code,
      });
      // 直冲类型，填写账号
      if (skuData.top_up_type === 1 || skuData.top_up_type === 2) {
        toast.clear();
        sku.value = skuData;
        showDialog.value = true;
        temp.value = item;
        return;
      }
      const fn =
        props.activityInfo.object === "keep"
          ? handleKeepActivityReward
          : handleActivityReward;
      fn({
        activity_name: props.activityName,
        source: "会员权益",
        record_id: item.record_id,
        reward_platform: props.activityInfo.reward_platform,
        order_number: item.extra_info.order_number,
      })
        .then(() => {
          toast.clear();
          toUse(item.extra_info);
        })
        .catch(() => {
          toast.clear();
        });
    };

    const close = () => {
      if (!disabled.value) {
        top_up_account.value = "";
      }
    };

    const beforeClose = (action, done) => {
      if (action === "confirm") {
        done(false);
      } else {
        done();
      }
    };

    const confirm = () => {
      if (!disabled.value) {
        if (!top_up_account.value) {
          vm.$toast("请输入手机号");
          return;
        }
        if (!/^1[3-9]\d{9}$/.test(top_up_account.value)) {
          vm.$toast("手机号格式不正确");
          return;
        }
      }

      const toast = vm.$loading();
      const fn =
        props.activityInfo.object === "keep"
          ? handleKeepActivityReward
          : handleActivityReward;
      fn({
        top_up_account: top_up_account.value,
        activity_name: props.activityName,
        source: "会员权益",
        record_id: temp.value.record_id,
        reward_platform: props.activityInfo.reward_platform,
        order_number: temp.value.extra_info.order_number,
      })
        .then(() => {
          showDialog.value = false;
          toast.clear();
          toUse(temp.value.extra_info);
        })
        .catch(() => {
          toast.clear();
        });
    };

    return {
      CDN,
      show,
      open,
      toUse,
      toGet,
      showDialog,
      top_up_account,
      disabled,
      close,
      confirm,
      beforeClose,
    };
  },
});
</script>

<style lang="less" scoped>
.draw-btn-wrap {
  .draw-btn {
    box-sizing: border-box;
    border: 1px solid #fff;
    color: #fff;
    font-size: 16px;
    margin-top: 20px;
  }
}

.luck-draw-record {
  margin: 0 15px;
  border-top: 1px solid #dcdcdc;
  padding-bottom: 30px;
  height: 400px;
  padding-top: 5px;
  overflow-y: scroll;
}
.draw-no-prize {
  text-align: center;
  font-size: 13px;
  color: #999;
  margin-top: 20px;
}
.draw-item {
  height: 87px;
  background: url("../../assets/images/draw/prize-bg.png") no-repeat 0 0;
  background-size: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.draw-item-l {
  width: 87px;
  height: 87px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.draw-name {
  font-size: 16px;
  color: #000;
  font-weight: bold;
  margin-left: 16px;
}
.draw-item-l img {
  width: 51px;
  height: 51px;
}
.draw-use-btn {
  display: flex;
  width: 56px;
  height: 21px;
  background: #ff0073;
  border-radius: 11px;
  line-height: 21px;
  color: #fff;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  margin-right: 9px;
  margin-left: auto;
}

.account-doalog-main {
  padding-top: 30px;
  margin-bottom: 30px;
  .title {
    text-align: center;
    margin-bottom: 20px;
  }
  .field-input {
    background: #eee;
    border-radius: 8px;
  }
}
</style>
