var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('div',[(!_vm.activityList.length)?_c('div',{staticClass:"activity-empty"},[_c('van-image',{staticClass:"empty-image",attrs:{"fit":"cover","lazy-load":"","src":require('../../assets/images/activity/empty3.png')}}),_c('div',{staticClass:"empty-text"},[_c('p',[_c('span',[_vm._v(_vm._s(_vm.detail.activity_preface || "每周定期"))]),_vm._v(" 秒杀活动开启 ")]),_c('p',[_vm._v("请届时再来，你可先返回使用其它会员权益")])])],1):_c('main',{staticClass:"seckill"},[_c('div',{staticClass:"activity-bg"},[_c('van-image',{staticClass:"activity-bg-image",attrs:{"fit":"cover","lazy-load":"","src":_vm.activity.header_image_url ||
          'https://cdn.xiangshuheika.com/static/activity/header_image_url.png'}}),_c('div',{staticClass:"activity-rule",on:{"click":_vm.showRule}},[_vm._v("规则")])],1),_c('van-tabs',{attrs:{"swipeable":"","animated":"","line-width":60},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},_vm._l((_vm.activityList),function(activity){return _c('van-tab',{key:activity.id,scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"activity-time"},[_c('div',{staticClass:"day"},[_vm._v(_vm._s(_vm.day(activity.start_time)))]),_c('div',{staticClass:"time"},[_vm._v(_vm._s(_vm.time(activity.start_time)))])]),_c('div',{staticClass:"activity-status"},[_c('div',{staticClass:"tip",class:_vm.statueClassEnum[activity.status].class},[_vm._v(" "+_vm._s(_vm.statueClassEnum[activity.status].text)+" ")]),_vm._v(" "+_vm._s(_vm.statusEnum[activity.status].statusName)+" ")])]},proxy:true}],null,true)},[_c('div',{staticClass:"products"},[(activity.status !== 'end')?_c('div',{staticClass:"activity-count-down"},[_c('div',{staticClass:"activity-count-title"},[_vm._v(_vm._s(_vm.activityName))]),_c('van-count-down',{staticClass:"count-down",attrs:{"time":_vm.getCountTime},on:{"finish":_vm.finish},scopedSlots:_vm._u([{key:"default",fn:function(timeData){return [(timeData.days > 0)?[_c('span',{staticClass:"block"},[_vm._v(_vm._s(timeData.days)+"天")]),_c('span',{staticClass:"colon"},[_vm._v(":")])]:_vm._e(),_c('span',{staticClass:"block"},[_vm._v(_vm._s(_vm._f("zero")(timeData.hours)))]),_c('span',{staticClass:"colon"},[_vm._v(":")]),_c('span',{staticClass:"block"},[_vm._v(_vm._s(_vm._f("zero")(timeData.minutes)))]),_c('span',{staticClass:"colon"},[_vm._v(":")]),_c('span',{staticClass:"block"},[_vm._v(_vm._s(_vm._f("zero")(timeData.seconds)))])]}}],null,true)})],1):_vm._e(),_vm._l((activity.sku_list),function(item){return _c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
              event_id: 'seckill_jump_detail_click',
              namespace: _vm.namespace,
              item_code: item.item_code,
              activity_name: _vm.activityQueryName,
              start_time: activity.start_time,
              activity_status: activity.status,
            }),expression:"{\n              event_id: 'seckill_jump_detail_click',\n              namespace,\n              item_code: item.item_code,\n              activity_name: activityQueryName,\n              start_time: activity.start_time,\n              activity_status: activity.status,\n            }"}],key:item.item_code,staticClass:"product-item",on:{"click":function($event){return _vm.toDetail(
                item,
                activity.start_time,
                activity.start_time,
                activity.status
              )}}},[_c('div',{staticClass:"product-img-wrap"},[_c('van-image',{attrs:{"fit":"cover","lazy-load":"","src":item.image}})],1),_c('div',{staticClass:"product-content"},[_c('div',{staticClass:"product-title"},[_c('p',{staticClass:"product-name"},[_vm._v(_vm._s(item.title))])]),_c('div',{staticClass:"product-context",class:_vm.getComputedStatus(item, 'actionClass')},[_c('div',{staticClass:"spike"},[_c('div',{staticClass:"spike-text"},[_vm._v(_vm._s(item.obtain_tips)+"¥")]),_c('div',{staticClass:"spike-price"},[_vm._v(_vm._s(item.activity_price))])]),_c('div',{staticClass:"price-wrap"},[_c('div',{staticClass:"face-value",class:_vm.getMinFontSize(item)},[_c('span',[_vm._v("¥")]),_vm._v(_vm._s(_vm.getValue(item))+" ")]),_c('div',{staticClass:"price-text"},[_vm._v("已补")])]),_c('div',{staticClass:"action-wrap"},[_c('div',{staticClass:"head"},[_c('img',{class:_vm.computedActionIcon(item),attrs:{"src":require("@/assets/images/seckill-run-icon.png")}}),_c('span',{class:_vm.computedActionIcon(item)},[_vm._v(_vm._s(_vm.getStartTime)+" ")])]),_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.getBtnText(item))+" "),_c('img',{class:_vm.computedActionIcon(item),attrs:{"src":require("@/assets/images/left-white-arrow.png")}})])])])])])})],2)])}),1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }