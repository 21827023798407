<template>
  <section class="unxp" v-if="!fetching">
    <section class="header">
      <van-image :src="require('@/assets/images/smile.png')" class="smile" />
      <div>
        <div class="header-name">
          {{ user_phone | maskStr }}
        </div>
        <div class="header-subtext">
          {{
            $getConfigValue("SHOW_REMAIN_DAYS")
              | formatText({
                remain_days: card.current_package_remain_days,
                expiry_date: card.card_expiry_date,
              })
          }}
        </div>
      </div>
      <div class="renewal">
        <template v-if="canRenewal && isActivates">
          <img
            src="https://common-h5-dev.xiangshuheika.com/static/upload/202405117d2f1157a2dd5.png"
            alt=""
          />
          <p>续费有礼,免费领权益</p></template
        >
        <div @click="toPay" v-if="canRenewal">立即续费</div>
      </div>
    </section>
    <section class="benefit" v-if="card.card_status === 'active'">
      <div class="page-benefits">
        <component
          :is="isActiveFloor(item.display_code)"
          :benefit="item"
          :key="idx"
          v-for="(item, idx) in catesgoryList"
        />
      </div>
    </section>
    <div v-else-if="card.card_status === 'expired'" class="card-past">
      <van-image :src="require('@/assets/images/yh-empty.png')" />
      <p>您的权益已过期</p>
    </div>
    <div v-else-if="card.card_status === 'refund'" class="card-past">
      <van-image :src="require('@/assets/images/yh-empty.png')" />
      <p>您的权益已退款</p>
    </div>
    <div class="footer">
      <div class="item" @click="$router.push('/order')">
        <van-image
          class="img"
          :src="require('@/assets/images/icon-order2.png')"
        />
        我的订单
      </div>
      <div class="item" @click="$router.push('/service')">
        <van-image class="img" :src="require('@/assets/images/server.png')" />
        在线客服
      </div>
    </div>
    <!-- <div
      class="activity-sidebar-entry"
      v-if="unxpActivity.dialogImage && activitySiteBar"
    >
      <van-image
        @click="toLuckdraw"
        class="activity-sidebar-entry-image"
        :src="unxpActivity.sidebarImage"
      />
    </div>
    <oakvip-overlay v-model="activityOverlay" v-if="firstInit">
      <van-image
        @click="toLuckdraw"
        class="activity-overlay-image"
        :src="unxpActivity.dialogImage"
      ></van-image>
    </oakvip-overlay> -->
  </section>
</template>

<script>
import { useRouter, useStore } from "@/components/use/base";
import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from "@vue/composition-api";
import Vue from "vue";

import useMemberBenefitCategory from "@/use/useMemberBenefitCategory";
import { getUserDrawAppoint } from "@/apis/luckdraw";
import useVisitorCategory from "@/use/useVisitorCategory";
import { toPayV2 } from "@/helper/pay";
import { getActivityInfo } from "@/apis";

import floor from "./floor.vue";
import activeFloor from "./activeFloor.vue";

const vm = Vue.prototype;

let first = true;
export default defineComponent({
  components: {
    floor,
    activeFloor,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const fetching = ref(true);
    const activityOverlay = ref(false);
    const activitySiteBar = ref(false);
    const firstInit = ref(first);
    const isActivates = ref(false);

    const { card, catesgorys, getProductList } = useMemberBenefitCategory();
    const { fetchPackage, fetchPaymethod, currentMethod, currentPackage } =
      useVisitorCategory();

    const canRenewal = computed(() => {
      const renewalCount = store.state.userInfo.valid_ch_count;
      return renewalCount < 4;
    });

    const unxpActivity = computed(() => {
      const activity = vm.$getConfigValue("UNXP_MEMBER_OPEN_ACTIVITY");
      if (!activity) {
        return {};
      }
      try {
        const res = JSON.parse(activity);
        if (!res.enable) {
          return {};
        }
        return res;
      } catch (e) {
        console.log(e);
        return {};
      }
    });

    const isActiveFloor = computed(() => {
      return (display_code) => {
        return display_code === "unxp_addbenefit_v2" ? "activeFloor" : "floor";
      };
    });

    // 是否参与了活动
    const getActivityStatus = async () => {
      try {
        const activity_res = await getActivityInfo({
          activity_name: "unxp_add_benefit_point_for_gift",
        });
        isActivates.value =
          activity_res.data.is_active &&
          store.getters.getConfigValue("UNXP_ACTIVITIES");
      } catch (error) {
        console.log(error);
        isActivates.value = false;
      }
    };

    const catesgoryList = computed(() => {
      const res = [];
      catesgorys.value.forEach(async (item) => {
        item.list.forEach(async (r) => {
          r.item_code = item.desc;
        });
        if (item.display_code === "unxp_addbenefit" && isActivates.value) {
          res.push(item);
        } else if (item.display_code !== "group") {
          res.push(item);
        } else {
          let groupCategory = res.find((item) => item.display_code === "group");
          if (groupCategory) {
            groupCategory.list = [...groupCategory.list, ...item.list];
          } else {
            res.push(item);
          }
        }
      });
      return res;
    });

    const toLuckdraw = () => {
      router.push(`/luckdraw?activity_name=${unxpActivity.value.activityName}`);
    };

    const toPay = () => {
      let url =
        (window.location.origin + window.location.pathname).replace(/\/$/, "") +
        `/#/index`;
      const return_url = encodeURIComponent(url);
      toPayV2(currentPackage.value, currentMethod.value.channel, {
        partner_jump_url: return_url,
      });
    };

    onMounted(async () => {
      await getActivityStatus();
      await Promise.all([fetchPackage(), fetchPaymethod()]);
      const loading = vm.$loading();
      try {
        await getProductList();
        if (unxpActivity.value.enable) {
          const res = await getUserDrawAppoint({
            activity_name: unxpActivity.value.activityName,
            hideMsg: true,
          });
          if (res.data.used || res.data.number > 0) {
            activityOverlay.value = true;
            activitySiteBar.value = true;
          }
        }
      } finally {
        fetching.value = false;
        loading.clear();
        first = false;
      }
    });
    return {
      catesgoryList,
      fetching,
      card,
      user_phone: store.state.userInfo.login_phone_number,
      unxpActivity,
      activityOverlay,
      toLuckdraw,
      toPay,
      isActiveFloor,
      firstInit,
      activitySiteBar,
      isActivates,
      canRenewal,
    };
  },
});
</script>


<style lang="less" scoped>
.unxp {
  padding: 12px;
}

.header {
  height: 133px;
  border-radius: 10px;
  background: url("~@/assets/images/unxp_member_header_bg.png") no-repeat left
    center/cover;
  margin-bottom: 12px;
  display: flex;
  padding: 20px 12px;
  box-sizing: border-box;
  position: relative;
  color: #fff;
  .renewal {
    position: absolute;
    right: 13px;
    bottom: 18px;
    height: 28px;
    background: white;
    box-shadow: 0px 0px 9px 4px rgba(255, 255, 255, 0.3);
    border-radius: 14px;
    display: flex;
    justify-content: space-between;
    > img {
      height: 38px;
      width: 38px;
      margin-top: -10px;
      margin-left: -2px;
    }
    > p {
      margin-left: -5px;
      margin-right: 5px;
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      font-size: 11px;
      color: #ff6600;
      line-height: 28px;
      text-align: center;
      white-space: nowrap;
    }
    > div {
      width: 71px;
      height: 28px;
      background: linear-gradient(180deg, #ffea8a 0%, #ffc83d 100%);
      border-radius: 14px;
      font-weight: 600;
      font-size: 13px;
      color: #ea5524;
      line-height: 28px;
      text-align: center;
    }
  }

  .smile {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .header-name {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
  }
  .header-subtext {
    line-height: 22px;
    color: rgba(#fff, 0.7);
  }
}

.page-benefit {
  background: #fff;
  border-radius: 10px;
  padding: 16px 12px 0px;
  & + .page-benefit {
    margin-top: 12px;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;

  .item {
    background-color: #fff;
    height: 55px;
    border-radius: 10px;
    width: 49%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    color: #333;

    .img {
      width: 22px;
      height: 22px;
      margin-right: 4px;
    }
  }
}

.activity-overlay-image {
  width: 360px;
}

.activity-sidebar-entry {
  position: fixed;
  right: 0;
  top: 70%;
}

.activity-sidebar-entry-image {
  width: 72px;
}
</style>