<template>
  <div>
    <div :class="namespace" v-if="card_info.card_type && showIndexPage">
      <div class="yh-home">
        <div class="yh-header">
          <van-image
            class="yh-header-img"
            :src="`https://cdn.xiangshuheika.com/static/${namespace}/index-header.png`"
          />
          <div class="yh-title" v-html="headerTitle"></div>
          <div
            v-if="card_info.card_status !== 'refund'"
            :class="{
              'yh-validity': true,
              'mdd-validity': namespace === 'mdd',
            }"
          >
            {{
              $getConfigValue("SHOW_REMAIN_DAYS")
                | formatText({
                  remain_days: card_info.current_package_remain_days,
                  expiry_date: (card_info.card_expiry_date || "").replace(
                    /-/g,
                    "."
                  ),
                })
            }}
          </div>
        </div>
        <div class="yh-benefit-list">
          <div
            v-for="(item, seq) in benefit_list"
            class="yh-benefit-item"
            :key="item.type"
          >
            <div class="yh-benefit-first">
              <van-image
                class="yh-benefit-item-icon"
                mode="aspectFit"
                :src="item.icon_url"
              />
              <div class="yh-benefit-item-text">
                <div>{{ item.title }}</div>
                <div class="yh-benefit-item-text-desc">{{ item.subtitle }}</div>
              </div>
            </div>
            <div
              v-if="item.type !== 'freeoutpatient' && item.type !== 'yioil'"
              class="yh-benefit-item-num"
            >
              x{{ item.amount + item.amount_reserved }}
            </div>
            <div
              v-ans="{
                event_id: 'benefit_list_click',
                sequence: seq,
                spu_id: item.type,
                spu_name: item.title,
              }"
              class="yh-benefit-item-btn"
              @click="jump(item)"
            >
              {{
                item.type.includes("keepvip") && namespace === "keep"
                  ? "查看"
                  : "使用"
              }}
            </div>
          </div>
          <div v-if="card_info.card_status === 'expired'" class="card-past">
            您的权益已过期
          </div>
          <div v-if="card_info.card_status === 'refund'" class="card-past">
            您的权益已退款
          </div>
        </div>
        <div class="yh-footer">
          <div class="yh-footer-item" @click="$router.push('/order')">
            <div class="yh-footer-icon yh-footer-order"></div>
            我的订单
          </div>
          <div
            v-if="namespace.startsWith('zjl')"
            class="yh-footer-item"
            @click="$router.push('/help')"
          >
            <div class="yh-footer-icon yh-footer-server"></div>
            常见问题
          </div>
          <div v-else class="yh-footer-item" @click="$router.push('/service')">
            <div class="yh-footer-icon yh-footer-server"></div>
            在线客服
          </div>
        </div>
        <div
          v-if="$getConfigValue('SHOW_REDEEM')"
          class="index-redeem"
          @click="$router.push('/redeem')"
        >
          兑换会员&gt;
        </div>
        <div
          v-if="!is_prod"
          class="index-redeem"
          @click="$router.push('/help')"
        >
          帮助中心&gt;
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserBenefit, queryUserBenefitUse } from "@/apis/home";
import { mapMutations, mapState } from "vuex";
import { NoticeBar } from "vant";

import { getAtivityRecord } from "@/apis/luckdraw";

export default {
  name: "index",
  components: {
    [NoticeBar.name]: NoticeBar,
  },
  data() {
    return {
      is_prod: process.env.VUE_APP_RUNTIME_ENV === "production",
      card_info: {
        current_cycle_expired_date: "",
        card_expiry_date: "",
        card_status: "expired",
      },
      benefit_list: [],
      keepEntry:
        !!this.$route.query.once || !!sessionStorage.getItem("keepEntry"),
    };
  },

  methods: {
    ...mapMutations(["changeState"]),
    async jump(item) {
      if (this.$getConfigValue("LIMIT_USER_BENEFIT")) {
        const res = await queryUserBenefitUse();
        if (res.data === "used") {
          this.$alert({
            message: "每天仅可使用1项权益，你今天已用过，请明天再来",
            confirmButtonText: "好的",
          });
          return;
        }
      }
      this.$router.push(`/benefitDetail?benefit_type=${item.type}`);
    },
  },
  async mounted() {
    if (!this.showIndexPage) {
      this.$toast("页面不存在");
      return;
    }
    //省呗抽奖后，需要进入首页使用权益，未参加活动的不可进入
    if (this.namespace === "sy") {
      const res = await getAtivityRecord({ activity_name: "wishactivity" });
      if (!res.data.length) {
        this.$toast("活动不定期开放，敬请期待");
        setTimeout(() => {
          window.history.back();
        }, 2000);
        return;
      }
    }
    const loading = this.$loading();
    let opts = {};
    // 众安会传一个benefit_source参数
    // 省呗等级会员 会传一个auto参数进来，但不能传给后端
    if (this.benefit_source && this.benefit_source !== "auto") {
      opts.benefit_source = this.benefit_source;
    }
    if (this.package_name) {
      opts.package_name = this.package_name;
    }
    try {
      const res = await getUserBenefit(opts);
      this.benefit_list = (res.data.benefit_list || [])
        .filter((r) => r.title)
        .filter((r) =>
          this.namespace === "rongshu"
            ? ["rsmusic", "Nphone", "video_new"].indexOf(r.type) === -1
            : true
        );
      this.card_info = res.data;
    } finally {
      loading.clear();
    }
    if (this.namespace === "lnxm" && !localStorage.getItem("toast")) {
      this.$toast("领取成功！");
      localStorage.setItem("toast", "lnxmToast");
    }
  },
  computed: {
    ...mapState([
      "namespace",
      "namespaceInfo",
      "userInfo",
      "benefit_source",
      "package_name",
    ]),
    showIndexPage() {
      return (
        !this.is_prod ||
        !!this.$getConfigValue("INDEX_TITLE") ||
        this.$route.query.debug == 1
      );
    },
    headerTitle() {
      return this.$getConfigValue("INDEX_TITLE");
    },
  },
};
</script>
<style scoped lang="less">
.yh-home {
  min-height: 100vh;
  background-color: #f5f5f5;
}
.yh-header {
  height: 165px;
  position: relative;
}
.yh-header-img {
  width: 100%;
  height: 150px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.yh-title {
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  color: #ffe9cd;
  padding-top: 45px;
  margin-bottom: 10px;
  position: relative;
  z-index: 2;
  height: 28px;
}
.yh-validity {
  text-align: center;
  font-size: 13px;
  color: #887963;
  position: relative;
  z-index: 2;
}
.yh-benefit-list {
  position: relative;
  z-index: 3;
  margin: -36px 10px 0;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
.yh-benefit-item {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 16px 10px;
  justify-content: space-between;
}
.yh-benefit-first {
  display: flex;
  align-items: center;
}
.yh-benefit-item-icon {
  width: 30px;
  height: 30px;
  margin-right: 9px;
}
.yh-benefit-item-text {
  font-size: 16px;
  color: #222;
  font-weight: bold;
  width: 175px;
}
.yh-benefit-item-text-desc {
  font-size: 12px;
  color: #999;
  font-weight: normal;
  line-height: 19px;
}
.yh-benefit-item-btn {
  width: 60px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 25px;
  background: var(--background);
  font-size: 14px;
  color: var(--color);
}
.yh-benefit-item-num {
  font-size: 16px;
  color: #222;
  font-weight: bold;
}
.yh-footer {
  background-color: #fff;
  margin: 20px 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #333;
  height: 55px;
}
.yh-footer-item {
  flex: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.yh-footer-item::after {
  content: "";
  width: 1px;
  height: 19px;
  background-color: #dcdcdc;
  position: absolute;
  right: 0;
  top: 2px;
}
.yh-footer-item:last-child:after {
  display: none;
}
.yh-footer-icon {
  width: 22px;
  height: 22px;
  margin-right: 8px;
  background-repeat: no-repeat;
  background-size: 100%;
}
.yh-footer-order {
  background-image: url("../../assets/images/icon-order.png");
}
.yh-footer-server {
  background-image: url("../../assets/images/icon-server.png");
}
.card-past {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 18px;
  color: #333;
}
.bottom-margin {
  height: 20px;
}
.zjl01 .yh-benefit-item-btn,
.zjl03 .yh-benefit-item-btn,
.zjl06 .yh-benefit-item-btn,
.zjl12 .yh-benefit-item-btn {
  width: 60px;
  height: 25px;
  border: 1px solid #bb7518;
  border-radius: 4px;
  background: #fff;
  color: #bb7518;
}
.zjl01 .yh-footer-order,
.zjl03 .yh-footer-order,
.zjl06 .yh-footer-order,
.zjl12 .yh-footer-order {
  background-image: url("../../assets/images/zjlicon-order.png");
}
.zjl01 .yh-footer-server,
.zjl03 .yh-footer-server,
.zjl06 .yh-footer-server,
.zjl12 .yh-footer-server {
  background-image: url("../../assets/images/zjlicon-server.png");
}
.zjl01 .yh-benefit-list,
.zjl03 .yh-benefit-list,
.zjl06 .yh-benefit-list,
.zjl012 .yh-benefit-list {
  margin-top: 10px;
}

.zjl01 .yh-header,
.zjl03 .yh-header,
.zjl06 .yh-header,
.zjl12 .yh-header {
  .yh-title {
    padding-top: 70px;
    color: #55220a;
    font-size: 22px;
  }
  .yh-validity {
    color: #55220a;
  }
}

.keep .yh-title {
  display: none;
}
.keep .yh-header {
  height: 200px;
}
.keep .yh-header-img {
  height: 200px;
}
.keep .yh-validity {
  padding-top: 70px;
  color: #fff;
  opacity: 0.7;
  font-size: 12px;
}

.keep .yh-benefit-list {
  margin-top: -50px;
}
.index-redeem {
  text-align: center;
  font-size: 12px;
  color: #0091ff;
}

.logout-account {
  position: absolute;
  top: 18px;
  left: 0;
  height: 24px;
  width: 70px;
  text-indent: 15px;
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 0 26px 26px 0;
  background-image: url("../../assets/images/logout.png");
  background-size: 8px;
  background-position: 5px center;
  background-repeat: no-repeat;
  font-size: 12px;
  line-height: 24px;
  z-index: 10;
}

.notice-bar {
  height: 24px;
  background: #ffb999;
  font-size: 12px;
  color: #fff;
}
.mdd-validity {
  margin-top: 32px;
}
</style>