<template>
  <div class="card">
    <img class="icon" :src="cardValue.display_img_url" />
    <div class="content">
      <div class="title">{{ cardValue.selling_points }}</div>
      <div class="desc">{{ cardValue.desc }}</div>
    </div>
    <div
      class="action"
      v-ans="{
        event_id: 'benefit_list_click',
        sequence: index,
        namespace,
        spu_id: cardValue.id,
        spu_name: cardValue.name,
      }"
      @click="jump"
    >
      去点餐
    </div>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

import { useStore } from "@/components/use/base";
import { queryH5JumpUrl } from "@/apis/product";

export default defineComponent({
  props: ["cardValue"],
  setup({ cardValue, index }) {
    const store = useStore();

    const namespace = store.state.namespace;
    const userInfo = store.state.userInfo;

    const jump = async () => {
      if (cardValue.partner_jump_url) {
        location.href = cardValue.partner_jump_url;
      } else {
        const url = await queryH5JumpUrl({
          user_id: userInfo.id,
          generate_h5_code: cardValue.generate_h5,
          spu_id: cardValue.id,
          phone_number: userInfo.user_phone,
        });
        location.href = url.data;
      }
    };

    return {
      cardValue,
      namespace,
      index,
      jump,
    };
  },
});
</script>

<style scoped lang='less'>
.card {
  width: 100%;
  height: 80px;
  background: url("https://common-h5-dev.xiangshuheika.com/static/upload/20240723c6e90df728bc7.png")
    no-repeat;
  background-size: contain;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  .icon {
    height: 82px;
    width: 82px;
    border-radius: 4px;
  }
  .content {
    margin-left: 12px;
    .title {
      font-weight: 600;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
      text-align: left;
    }
    .desc {
      margin-top: 4px;
      font-weight: 400;
      font-size: 12px;
      color: #9b9b9b;
      line-height: 17px;
    }
  }
  .action {
    position: absolute;

    width: 60px;
    height: 26px;
    background: linear-gradient(117deg, #fec454 0%, #ff8500 100%), #fffefa;
    border-radius: 13px;

    font-weight: 600;
    font-size: 12px;
    color: #ffffff;
    line-height: 26px;
    text-align: center;

    right: 12px;
  }
}
</style>
