<template>
  <section class="unxp">
    <div class="header" :class="isActivates && 'activity'">
      <div class="activity-header-bg" v-if="unxpActivity.headerImage">
        <van-image
          :src="
            isActivates
              ? unxpActivity.activitesheader
              : unxpActivity.headerImage
          "
        />
        <template v-if="isActivates">
          <div class="active-wrap">
            <div class="active-btn" @click="handleDraw">开通免费领</div>
          </div>
        </template>
      </div>
      <VerticalSwiper class="swiper" />
      <div class="to-use" v-if="showToUse" @click="sumbit">已是会员,去使用</div>
      <div
        class="draw"
        v-for="(item, index) of drawArr"
        :key="index"
        :style="item"
        @click="handleDraw"
      >
        去领取<svg-icon iconClass="white-arrow" class="draw-icon" />
      </div>
    </div>
    <van-image
      :src="unxpActivity.activeBenefit"
      v-if="isActivates"
      class="benefit-banner"
    />
    <div
      @click="handleDraw"
      class="activity-middle-image"
      v-if="unxpActivity.adImage"
    >
      <van-image :src="unxpActivity.adImage" />
    </div>
    <van-sticky :offset-bottom="-50" class="sticky m12">
      <div class="btn-wrap resizing">
        <div
          class="buy-btn animate-run"
          @click="sumbit"
          v-ans="{ event_id: 'visitor_click_buy' }"
        >
          <div class="price">
            <span class="sub">¥</span>
            <CountUp
              :endCount="currentPackage.price"
              :startVal="currentPackage.face_value"
            /><span class="face-value"
              >价值 ¥{{ currentPackage.face_value }}</span
            >
          </div>
          <span class="qiang">抢</span>
        </div>
        <van-count-down :time="countDown" class="count-down">
          <template #default="timeData">
            秒杀中
            <span class="block">{{ timeData.hours }}</span>
            <span class="colon">:</span>
            <span class="block">{{ timeData.minutes | zero }}</span>
            <span class="colon">:</span>
            <span class="block">{{ timeData.seconds | zero }}</span>
            后失效
          </template>
        </van-count-down>
      </div>
      <div class="agreement">
        购买前请阅读<a
          href="https://cdn.xiangshuheika.com/h5/agreement/xiaopai.html"
          >《服务协议》</a
        >,购买激活之日起一年有效期
      </div>
    </van-sticky>
    <div class="question-wrap m12">
      <div class="title">
        <span>问大家（1.8万人也在问）</span>
        <span @click="jumpQuestion"
          >查看全部<svg-icon iconClass="arrow2" class="icon"
        /></span>
      </div>
      <div class="question"><span>问</span>这个联合会员咋用啊，4选1是啥？</div>
      <div class="desc">
        <span>137******28</span>
        <span>年卡会员</span>
      </div>
      <div class="answer">
        小湃的老用户了，电视盒子都是他家的，去年就开了联合会员今年又续上了，已经省大几百了。开卡后每月可以任意选4大影视会员，腾讯爱奇艺芒果优酷每个月都换着领…
      </div>
      <div class="question">
        <span>问</span>这个卡真的好用吗？ 哪位亲可以分享下呀？
      </div>
      <div class="desc">
        <span>158******57 </span>
        <span>年卡会员</span>
      </div>
      <div class="answer">
        小湃的老用户了，电视盒子都是他家的，去年就开了联合会员今年又续上了，已经省大几百了。开卡后每月可以任意选4大影视会员，腾讯爱奇艺芒果优酷每个月都换着领…
      </div>
    </div>
    <div class="rule m12">
      <div class="tips">购买须知</div>
      <div class="rule-title">影视会员权益说明</div>
      <div
        class="rule-content"
        v-html="namespaceInfo.rule"
        :class="{ 'rule-showMore': showMore }"
      />
      <div
        class="show-more"
        @click="handleShowMore"
        :class="{ isShowMore: showMore }"
      >
        {{ showMore ? "收起" : "展开更多"
        }}<svg-icon iconClass="arrow2"></svg-icon>
      </div>
    </div>
    <div class="use-entry m12">
      <van-image :src="require('@/assets/images/unxp_footer.png')" />
    </div>
    <DrawModal
      :visible="visible"
      v-if="visible"
      @update:visible="visible = $event"
      :submit="sumbit"
      :count="currentPackage.price"
    />
    <RuleModal
      :visible="ruleModalVisible"
      v-if="ruleModalVisible"
      @update:visible="ruleModalVisible = $event"
    />
  </section>
</template>

<script>
import {
  defineComponent,
  onMounted,
  ref,
  computed,
} from "@vue/composition-api";
import dayjs from "dayjs";

import { useStore, useRouter } from "@/components/use/base";
import loginKeepDialog from "@/components/loginKeepDialog";
import VerticalSwiper from "@/components/VerticalSwiper";
import CountUp from "@/components/Countup/index.vue";
import useVisitorCategory from "@/use/useVisitorCategory";
import { toPayV2 } from "@/helper/pay";
import ans from "@/utils/ans";
import { jsonSafeParse } from "@/utils";

import DrawModal from "./DrawModal";
import RuleModal from "./RuleModal.vue";
import Vue from "vue";

const vm = Vue.prototype;
export default defineComponent({
  components: {
    VerticalSwiper,
    DrawModal,
    CountUp,
    RuleModal,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const visible = ref(false);
    const loading = ref(true);
    const checked = ref(true);
    const drawArr = ref([]);
    const countDown = ref(0);
    const showMore = ref(false);
    const isActivates = ref(false);
    const activatesBenefit = ref([]);
    const ruleModalVisible = ref(false);

    const isMember = store.state.isMember;
    const isLogin = !!store.state.token;

    const { fetchPackage, fetchPaymethod, currentMethod, currentPackage } =
      useVisitorCategory();

    onMounted(async () => {
      isActivates.value = store.getters.getConfigValue("UNXP_ACTIVITIES");
      activatesBenefit.value = jsonSafeParse(
        store.getters.getConfigValue("UNXP_ACTIVITIES_BENEFIT"),
        []
      );
      await Promise.all([fetchPackage(), fetchPaymethod()]);
      getCountDown();
      const drawPosition = JSON.parse(
        store.getters.getConfigValue("UNXP_VISTER_POSITION")
      );
      drawArr.value = isActivates.value
        ? drawPosition.map((item) => ({
            ...item,
            top: `${parseFloat(item.top) + 11.1}rem`,
          }))
        : drawPosition;
      console.log(drawArr.value);
    });

    const showToUse = computed(() => {
      if (!isLogin) return true;
      if (isLogin && !isMember) return false;
      return true;
    });

    const sumbit = async () => {
      let url =
        (window.location.origin + window.location.pathname).replace(/\/$/, "") +
        `/#/index`;
      const return_url = encodeURIComponent(url);
      if (!store.state.token) {
        await loginKeepDialog
          .show({
            title: "手机号验证",
            desc: "为保证您的会员权益及时到账，请先关联手机号",
            btnText: "完成",
            agreement: `<div style="text-align:center">购买则视为同意
        <a
          class="login-agreement-text"
          href="https://cdn.xiangshuheika.com/h5/agreement/pagj.html"
          >《服务协议》</a
        > </div>`,
          })
          .then((userInfo) => {
            ans.track("vistor_click_login_success");
            if (!userInfo.is_member) {
              toPayV2(currentPackage.value, currentMethod.value.channel, {
                partner_jump_url: return_url,
              });
            }
          });
        return;
      }
      toPayV2(currentPackage.value, currentMethod.value.channel, {
        partner_jump_url: return_url,
      });
    };

    const activatesRule = () => {
      ruleModalVisible.value = true;
    };

    const handleDraw = async () => {
      visible.value = true;
    };

    const jumpQuestion = () => {
      router.push("/unxpQuestion");
    };

    const unxpActivity = computed(() => {
      const activity = vm.$getConfigValue("UNXP_MEMBER_OPEN_ACTIVITY");
      if (!activity) {
        return {};
      }
      try {
        const res = JSON.parse(activity);
        if (!res.enable) {
          return {};
        }
        return res;
      } catch (e) {
        console.log(e);
        return {};
      }
    });

    const getCountDown = () => {
      const current = dayjs().valueOf();
      const tenHour = 36000000;
      const time = localStorage.getItem("unxp_modal_time");
      if (!time) {
        localStorage.setItem("unxp_modal_time", current);
        countDown.value = tenHour;
        return;
      }
      const timeOut = current - parseInt(time);
      if (timeOut > tenHour) {
        countDown.value = tenHour;
        localStorage.setItem("unxp_modal_time", current);
      } else {
        countDown.value = tenHour - timeOut;
      }
    };

    const handleShowMore = () => {
      showMore.value = !showMore.value;
    };

    return {
      loading,
      checked,
      sumbit,
      namespaceInfo: store.state.namespaceInfo,
      currentPackage,
      drawArr,
      handleDraw,
      visible,
      jumpQuestion,
      countDown,
      showToUse,
      showMore,
      handleShowMore,
      unxpActivity,
      isActivates,
      activatesBenefit,
      activatesRule,
      ruleModalVisible,
    };
  },
});
</script>


<style lang="less" scoped>
.unxp {
  background: #ea5524;
  min-height: 100vh;
  padding-bottom: 20px;
  box-sizing: border-box;
  overflow: hidden;
  .sticky {
    /deep/.van-sticky--fixed {
      top: auto;
      bottom: 0;
      background-color: #fff;
      padding: 10px 13px;
      .agreement {
        margin-top: 9px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 17px;
      }
      .buy-btn {
        background-image: url("../../../assets/images/xp-btn.png");
        margin-top: 0;
      }
      a {
        color: #0091ff;
      }
    }
  }
  .activity {
    height: 570px;
    padding-bottom: 0 !important;
    background: none !important;
  }
  .header {
    width: 375px;
    position: relative;
    background: url("~@/assets/images/unxp-header.png") no-repeat top/100%;
    padding-bottom: 171.73%;
    .swiper {
      position: absolute;
      top: 135px;
    }
    .draw {
      z-index: 1;
      display: flex;
      align-items: center;
      position: absolute;
      font-size: 10px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 15px;
      .draw-icon {
        font-size: 8px;
        margin-left: 2px;
      }
    }
    .to-use {
      position: absolute;
      background: linear-gradient(115deg, #dfbd94 0%, #ffe8c8 100%);
      border-radius: 11px 0px 0px 11px;
      padding: 3px 5px 3px 8px;
      top: 10px;
      right: 0px;
      z-index: 2;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #a96718;
      line-height: 14px;
    }
    .activities {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 11px;
      color: #ffffff;
      line-height: 16px;
      text-align: center;
      margin: 0 35px;
      margin-top: 3px;
      > span {
        text-decoration: underline;
        text-underline-offset: 4px;
      }
    }
    .activities-wrap {
      margin: 11px 16px 0px 16px;
      padding: 13px;
      background: #ffffff;
      border-radius: 13px;
      .benefit-wrap {
        display: flex;
        overflow-y: auto;
        margin-bottom: 11px;

        .activities-item {
          text-align: center;
          &:not(:last-child) {
            margin-right: 19px;
          }
          > img {
            width: 57px;
            height: 57px;
          }
          .activities-name {
            font-weight: 400;
            font-size: 11px;
            color: #333333;
            line-height: 16px;
          }
          .activities-price {
            white-space: nowrap;
            font-weight: 600;
            font-size: 10px;
            color: #fd5e01;
            line-height: 14px;
          }
        }
      }
      .activities-btn {
        width: 140px;
        height: 34px;
        background: linear-gradient(#ff9c5e 0%, #ff341b 100%);
        border-radius: 17px;
        margin: 0 auto;

        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 13px;
        color: #ffffff;
        line-height: 34px;
        text-align: center;
      }
    }
  }
  .benefit-banner {
    margin: 0 12px;
    height: 494px;
  }
  .buy-btn {
    z-index: 1;
    margin-top: 12px;
    display: flex;
    height: 53px;
    align-items: center;
    border-radius: 999px;
    padding-right: 17px;
    color: #fff;
    background-image: url("../../../assets/images/xp-btn.png");
    background-size: 100%;
  }
  .question-wrap {
    background: #ffffff;
    border-radius: 10px;
    padding: 14px 10px;
    margin-top: 18px;

    .title {
      display: flex;
      justify-content: space-between;
      align-content: center;
      > span {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 18px;
        &:first-child {
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
        }
      }
      > .icon {
        font-size: 8px;
        margin-left: 5px;
      }
    }
    .question {
      margin-top: 15px;
      font-size: 13px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      display: flex;
      align-items: center;

      > span {
        width: 14px;
        height: 14px;
        background: #f7d9b4;
        font-size: 9px;
        font-weight: 600;
        color: #666666;
        border-radius: 50%;
        text-align: center;
        line-height: 14px;
        margin-right: 6px;
      }
    }
    .desc {
      margin-left: 21px;
      margin-top: 7px;
      font-size: 11px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ac6428;
      line-height: 16px;
      > span {
        &:first-child {
          color: #999999;
          margin-right: 6px;
        }
      }
    }
    .answer {
      margin-top: 8px;
      margin-left: 21px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
    }
  }
  .guanfang {
    flex-basis: 116px;
    height: 100%;
    flex-shrink: 0;
    font-size: 17px;
    line-height: 53px;
    text-align: center;
    background: url("~@/assets/images/unxp_buy_bg.png") no-repeat left/100%;
    .font-family("PingFangSC-Medium");
  }
  .price {
    margin-left: auto;
    font-size: 40px;
    .font-family("PingFangSC-Semibold");
    .sub {
      font-size: 24px;
    }
    .face-value {
      font-size: 15px;
      margin-left: 4px;
      vertical-align: 8px;
      background: #fff;
      .font-family("PingFangSC-Semibold");
      padding: 2px 3px;
      color: #ff4828;
      border-radius: 11px 11px 11px 0px;
    }
  }
  .qiang {
    margin-left: 6px;
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
  }
}

.agreement {
  text-align: center;
  margin-top: 12px;
  color: #fff;
  a {
    color: #fff;
  }
}

.rule {
  margin-top: 13px;
  background: #fff;
  border-radius: 10px;
  position: relative;
  padding: 13px 10px 9px;
  margin-bottom: 12px;
  .tips {
    position: absolute;
    left: 0;
    top: 0;
    width: 60px;
    height: 21px;
    line-height: 21px;
    text-align: center;
    color: #fff;
    border-radius: 10px 0 10px 0;
    font-weight: bold;
    background: linear-gradient(360deg, #ff3922 0%, #fa6400 100%);
  }
  .rule-title {
    text-align: center;
    font-size: 18px;
    line-height: 25px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  .rule-content {
    color: #666;
    line-height: 18px;
    height: 90px;
    overflow: hidden;
    &.rule-showMore {
      height: auto;
    }
  }
  .show-more {
    width: auto;
    display: flex;
    justify-content: center;
    margin-top: 12px;
    /deep/ .svg-icon {
      font-size: 14px;
      vertical-align: -3px;
      transform: rotate(90deg);
      padding-left: 2px;
    }

    &.isShowMore {
      /deep/ .svg-icon {
        transform: rotate(270deg);
      }
    }
  }
}
.use-entry {
  margin-bottom: 102px;
}
.animate-run {
  overflow: hidden;
  position: relative;
  &::after {
    content: "";
    width: 26px;
    height: 100px;
    opacity: 1;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5)
    );
    position: absolute;
    left: 0;
    top: -20px;
    transform: rotate(30deg);
    animation: run 1s infinite;
    z-index: 100;
  }
}
.btn-wrap {
  position: relative;
  z-index: 99;
  .count-down {
    z-index: 1;
    position: absolute;
    right: 0;
    top: 0;
    background: #ff0000;
    border-radius: 8px 12px 12px 0px;
    padding: 1px 4px;
    font-size: 10px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 14px;
    transform: translateY(-50%);
  }
}
.activity-header-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 0;
}

.activity-middle-image {
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 17px;
}

.m12 {
  margin-left: 12px;
  margin-right: 12px;
}

.active-wrap {
  background: url("https://common-h5-dev.xiangshuheika.com/static/upload/202406189ae9037c1152a.png")
    no-repeat top/100%;
  height: 363px;
  width: 351px;
  margin: 0 auto;
  position: relative;

  .active-btn {
    position: absolute;
    width: 104px;
    height: 34px;
    background: linear-gradient(#ff9c5e 0%, #ff341b 100%);
    border-radius: 17px;

    font-size: 13px;
    color: #ffffff;
    line-height: 34px;
    text-align: center;

    left: 124px;
    top: 290px;
  }
}
</style>