<template>
  <div class="main">
    <div class="benefit-list">
      <div
        v-for="(item, seq) in benefitList"
        class="benefit-item"
        :key="item.type"
      >
        <van-image
          class="benefit-item-icon"
          mode="aspectFit"
          :src="item.icon_url"
        />
        <div class="benefit-item-text">
          <div class="title" v-html="getRichText(item.title)"></div>
          <div class="subtitle van-multi-ellipsis--l3">
            {{ item.subtitle }}
          </div>
        </div>
        <div
          v-ans="{
            event_id: 'benefit_list_click',
            sequence: seq,
            spu_id: item.type,
            spu_name: item.title,
          }"
          class="benefit-item-btn"
          @click="jump(item)"
        >
          去使用
        </div>
        <div class="times">
          可领取 {{ item.amount + item.amount_reserved }} 次
        </div>
      </div>
    </div>
    <section class="page-footer">
      <router-link class="order" to="/order"
        ><svg-icon icon-class="leiniao-order" />我的订单</router-link
      >
      <router-link class="service" to="/service"
        ><svg-icon icon-class="leiniao-service" />在线客服</router-link
      >
    </section>
  </div>
</template>

<script>
export default {
  props: ["benefitList", "getRichText"],
  methods: {
    jump(item) {
      this.$router.push(`/benefitDetail?benefit_type=${item.type}`);
    },
  },
};
</script>

<style scoped lang='less'>
.main {
  margin: 0 15px;
  padding: 10px 0 0 0;
  border-radius: 13px;
  background: #ffffff;
}
.benefit-body {
  position: relative;
  z-index: 1;
  margin: -10px 15px 0;
  padding-top: 10px;
  background-color: #fff;
  border-radius: 12px;
}
.benefit-list {
  position: relative;
  padding-left: 8px;
  padding: 0 10px 0 15px;
}
.benefit-item {
  position: relative;
  padding: 0 10px 0 16px;
  height: 72px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background: url("~@/assets/images/kukai_benefit_item_bg.png") no-repeat;
  background-size: cover;
  & + & {
    margin-top: 8px;
  }
}
.times {
  position: absolute;
  left: -10px;
  top: 0;
  width: 70px;
  height: 23px;
  line-height: 20px;
  color: #fff;
  background: url("~@/assets/images/kukai_benefit_tag.png") no-repeat;
  background-size: 100%;
  font-size: 10px;
  text-align: center;
}
.benefit-item-text {
  margin-right: 12px;
  flex: 1;
  .title {
    font-weight: bold;
    font-size: 14px;
    color: #202437;
    margin-bottom: 2px;
  }
  .subtitle {
    font-size: 10px;
  }
}
.benefit-item-icon {
  width: 45px;
  height: 45px;
  flex-shrink: 0;
  margin-right: 10px;
}

.benefit-item-btn {
  width: 60px;
  height: 23px;
  background: #fff;
  border-radius: 23px;
  color: #ec4d25;
  line-height: 23px;
  text-align: center;
  flex-shrink: 0;
}

.page-footer {
  display: flex;
  align-items: center;
  padding: 0 15px 0;
  margin-top: -10px;
  height: 92px;
  opacity: 0.82;
  box-sizing: border-box;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background: url("~@/assets/images/kukai_footer_bg.png") no-repeat;
  background-size: 100%;

  .svg-icon {
    font-size: 20px;
    margin-right: 6px;
    color: #fd4c14;
    vertical-align: -4px;
  }
  .order,
  .service {
    font-size: 15px;
    border-radius: 42px;
    height: 42px;
    line-height: 42px;
    width: 50%;
    border: 1px solid #ebebeb;
    color: #202437;
    text-align: center;
    line-height: 40px;
    vertical-align: -4px;
    font-weight: bold;
  }
  .order {
    margin-right: 10px;
  }
}
</style>
