var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.card_info.card_type && _vm.showIndexPage)?_c('div',{class:_vm.namespace},[_c('div',{staticClass:"yh-home"},[_c('div',{staticClass:"yh-header"},[_c('van-image',{staticClass:"yh-header-img",attrs:{"src":("https://cdn.xiangshuheika.com/static/" + _vm.namespace + "/index-header.png")}}),_c('div',{staticClass:"yh-title",domProps:{"innerHTML":_vm._s(_vm.headerTitle)}}),(_vm.card_info.card_status !== 'refund')?_c('div',{class:{
            'yh-validity': true,
            'mdd-validity': _vm.namespace === 'mdd',
          }},[_vm._v(" "+_vm._s(_vm._f("formatText")(_vm.$getConfigValue("SHOW_REMAIN_DAYS"),{ remain_days: _vm.card_info.current_package_remain_days, expiry_date: (_vm.card_info.card_expiry_date || "").replace( /-/g, "." ), }))+" ")]):_vm._e()],1),_c('div',{staticClass:"yh-benefit-list"},[_vm._l((_vm.benefit_list),function(item,seq){return _c('div',{key:item.type,staticClass:"yh-benefit-item"},[_c('div',{staticClass:"yh-benefit-first"},[_c('van-image',{staticClass:"yh-benefit-item-icon",attrs:{"mode":"aspectFit","src":item.icon_url}}),_c('div',{staticClass:"yh-benefit-item-text"},[_c('div',[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"yh-benefit-item-text-desc"},[_vm._v(_vm._s(item.subtitle))])])],1),(item.type !== 'freeoutpatient' && item.type !== 'yioil')?_c('div',{staticClass:"yh-benefit-item-num"},[_vm._v(" x"+_vm._s(item.amount + item.amount_reserved)+" ")]):_vm._e(),_c('div',{directives:[{name:"ans",rawName:"v-ans",value:({
              event_id: 'benefit_list_click',
              sequence: seq,
              spu_id: item.type,
              spu_name: item.title,
            }),expression:"{\n              event_id: 'benefit_list_click',\n              sequence: seq,\n              spu_id: item.type,\n              spu_name: item.title,\n            }"}],staticClass:"yh-benefit-item-btn",on:{"click":function($event){return _vm.jump(item)}}},[_vm._v(" "+_vm._s(item.type.includes("keepvip") && _vm.namespace === "keep" ? "查看" : "使用")+" ")])])}),(_vm.card_info.card_status === 'expired')?_c('div',{staticClass:"card-past"},[_vm._v(" 您的权益已过期 ")]):_vm._e(),(_vm.card_info.card_status === 'refund')?_c('div',{staticClass:"card-past"},[_vm._v(" 您的权益已退款 ")]):_vm._e()],2),_c('div',{staticClass:"yh-footer"},[_c('div',{staticClass:"yh-footer-item",on:{"click":function($event){return _vm.$router.push('/order')}}},[_c('div',{staticClass:"yh-footer-icon yh-footer-order"}),_vm._v(" 我的订单 ")]),(_vm.namespace.startsWith('zjl'))?_c('div',{staticClass:"yh-footer-item",on:{"click":function($event){return _vm.$router.push('/help')}}},[_c('div',{staticClass:"yh-footer-icon yh-footer-server"}),_vm._v(" 常见问题 ")]):_c('div',{staticClass:"yh-footer-item",on:{"click":function($event){return _vm.$router.push('/service')}}},[_c('div',{staticClass:"yh-footer-icon yh-footer-server"}),_vm._v(" 在线客服 ")])]),(_vm.$getConfigValue('SHOW_REDEEM'))?_c('div',{staticClass:"index-redeem",on:{"click":function($event){return _vm.$router.push('/redeem')}}},[_vm._v(" 兑换会员> ")]):_vm._e(),(!_vm.is_prod)?_c('div',{staticClass:"index-redeem",on:{"click":function($event){return _vm.$router.push('/help')}}},[_vm._v(" 帮助中心> ")]):_vm._e()])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }