<template>
  <main class="qr-code" :style="`background-color: ${theme_color}`">
    <van-empty v-if="showEmpty" description="券码不存在，请联系客服" />
    <template v-if="!loading && !showEmpty">
      <div class="showcode">
        <div class="showcode-main">
          <div v-if="status === 'used'" class="used"></div>
          <div v-if="status === 'expired'" class="expired"></div>
          <van-image
            lazy-load
            class="showcode-logo"
            fit="cover"
            :src="info.logo_url"
          />
          <div class="showcode-img" v-if="coupon_format.endsWith('code')">
            <qrcode-vue
              v-if="
                coupon_format === 'qr_code' || coupon_format === 'qr_bar_code'
              "
              :value="coupon_code + ''"
              :size="196"
              level="H"
            />
            <barcode
              v-if="
                coupon_format === 'bar_code' || coupon_format === 'qr_bar_code'
              "
              :value="coupon_code + ''"
              :width="1"
            >
              条形码生成失败，请联系客服
            </barcode>
            <div class="showcode-name">{{ info.item_name }}</div>
            <div class="showcode-code">{{ coupon_code }}</div>
          </div>
          <coupon-code v-else :payInfo="payInfo" :itemInfo="info" />
          <div class="showcode-tips" :style="`color: ${theme_color}`">
            {{ info.tips }}
          </div>
          <div class="showcode-line">
            <span
              class="semicircle"
              :style="`background: ${theme_color}`"
            ></span>
            <span
              class="semicircle2"
              :style="`background: ${theme_color}`"
            ></span>
          </div>
          <div class="showcode-explain">
            <div class="showcode-explain-title">- 使用条款 -</div>
            <div
              class="showcode-explain-content"
              v-html="info.instruction"
            ></div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="status === 'unused' && payInfo.coupon_enable">
      <div class="fixed-height"></div>
      <div class="mark">
        <div class="mark-btn" @click="markUsed">标记为已使用</div>
      </div>
    </template>
  </main>
</template>

<script>
import { getItemInfo, getOrderList } from "@/apis/home";
import QrcodeVue from "qrcode.vue";
import Barcode from "vue-barcode";
import CouponCode from "./CouponCode";
import { getStrFromJsonStr } from "@/utils";
import { getOrderDetail, getUserCoupon } from "@/apis";

export default {
  components: {
    QrcodeVue,
    Barcode,
    CouponCode,
  },
  data() {
    return {
      info: {},
      coupon_format: "",
      showEmpty: false,
      coupon_code: "",
      loading: false,
      status: "",
      payInfo: {},
    };
  },
  computed: {
    theme_color() {
      return this.info.attributes?.theme_color;
    },
  },
  async mounted() {
    const { item_code, coupon_code, coupon_format, order_number, status } =
      this.$route.query;
    if (!order_number) {
      this.showEmpty = true;
      return;
    }
    this.loading = true;
    const loading = this.$loading();
    try {
      await this.fetchOrder();
      const res = await getItemInfo({ item_code });
      this.coupon_code = coupon_code;
      this.coupon_format = coupon_format;
      this.info = res;
      this.status = status;
      this.info.logo_url = getStrFromJsonStr(
        res.special_info,
        "coupon_logo_url"
      );
    } finally {
      loading.clear();
      this.loading = false;
    }
  },
  methods: {
    async fetchOrder() {
      const { order_number } = this.$route.query;
      const order = await getOrderDetail({ order_number });
      const orderItem = order.data;
      if (orderItem) {
        this.payInfo = JSON.parse(orderItem.pay_info);
        this.payInfo.coupon_enable = orderItem.coupon_enable;
      }
    },
    markUsed() {
      const { order_number } = this.$route.query;

      this.$confirm({
        message: "标记为已使用后，可在“卡券-已使用”中查看",
        confirmButtonText: "继续标记",
      }).then(async () => {
        const loading = this.$loading();
        try {
          const res = await getUserCoupon({
            status: "used",
            is_save: "T",
            order_number,
          });
          this.status = res.data.status;
          this.$toast("标记成功");
        } finally {
          loading.clear();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.qr-code {
  padding: 40px 0;
}
.showcode-main {
  background-color: #fff;
  margin: 0 15px;
  border-radius: 8px;
  position: relative;
  padding: 1px 0;
}
.used {
  position: absolute;
  left: 250px;
  top: 100px;
  width: 88px;
  height: 88px;
  background-size: 88px;
  background: url("../../assets/images/used_tag.png") no-repeat;
  background-size: 100%;
  z-index: 100;
}
.expired {
  position: absolute;
  left: 250px;
  top: 100px;
  width: 88px;
  height: 88px;
  background-size: 88px;
  background: url("../../assets/images/expired_tag.png") no-repeat;
  background-size: 100%;
  z-index: 100;
}
.showcode-logo {
  position: absolute;
  width: 44px;
  height: 44px;
  background-color: #fff;
  border-radius: 50%;
  overflow: hidden;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
}
.showcode-img {
  margin: 45px auto 0;
  text-align: center;
}
.showcode-name {
  text-align: center;
  font-size: @font-md;
  margin-top: 7px;
  font-weight: bold;
  line-height: 22px;
}
.showcode-code {
  text-align: center;
  font-size: @font-xs;
  color: @text-gray-color;
  margin-top: 2px;
  line-height: 20px;
}
.showcode-tips {
  text-align: center;
  margin-top: 10px;
}
.showcode-line {
  border-bottom: 1px dashed #ccc;
  position: relative;
  margin-top: 20px;
  position: relative;
  &::before,
  &:after {
    content: "";
    position: absolute;
    top: -8px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #f8f8f8;
  }
  &::before {
    left: -8px;
  }
  &:after {
    right: -8px;
  }
}
.semicircle {
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
}
.semicircle2 {
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}

.showcode-explain {
  margin: 20px;
}
.showcode-explain-title {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}
.showcode-explain-content {
  color: @text-gray2-color;
  margin-top: 15px;
  white-space: pre-wrap;
  line-height: 1.5;
}

.mark {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  box-shadow: 0px -1px 0px 0px #ebecf1;
  padding: 5px 24px;
  box-sizing: border-box;
  padding-bottom: calc(5px + constant(safe-area-inset-bottom));
  padding-bottom: calc(5px + env(safe-area-inset-bottom));
  .mark-btn {
    background-color: #fff3da;
    height: 45px;
    line-height: 45px;
    border-radius: 45px;
    text-align: center;
    color: #ff9000;
    font-size: 16px;
  }
}
.fixed-height {
  height: 35px;
}
</style>
