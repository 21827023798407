<template>
  <section class="help-service" v-if="!loading">
    <help-order
      v-if="orders.length"
      :toService="toService"
      type="header"
      v-model="order"
      ref="helpOrderRef"
      :orders="orders"
    />
    <section class="special-category">
      <ul>
        <li
          v-for="item in specialCategory"
          :key="item.name"
          v-ans="{ event_id: 'help_service_group_click', title: item.name }"
          @click="selectCategoryClick(item)"
        >
          <div class="inner">
            <van-image lazy-load class="category-img" :src="item.image_url" />
            <div class="category-content ellipsis">
              <div class="title">
                {{ item.group }}
                <svg-icon class="title-icon" icon-class="category-arrow" />
              </div>
              <div class="sub-title ellipsis">{{ item.sub_title }}</div>
            </div>
          </div>
        </li>
      </ul>
    </section>
    <section class="default-category" v-if="defaultCategory.data.length > 0">
      <div class="top">
        <div class="title">猜您想问</div>
      </div>
      <ul class="help-list">
        <li
          class="help-list-item"
          v-for="(item, index) in defaultCategory.data"
          v-ans="{ event_id: 'help_service_help_click', title: item.title }"
          :key="item.id"
          @click="toHelpDetail(item, defaultCategory)"
        >
          <div class="name ellipsis">{{ index + 1 }}. {{ item.title }}</div>
          <svg-icon class="arrow-right" icon-class="arrow2" />
        </li>
      </ul>
    </section>
    <div class="height80"></div>
    <section class="footer">
      <van-button
        block
        class="footer-btn"
        type="info"
        v-ans="{
          event_id: 'help_service_to_udesk1',
          order_number: order.order_number,
          category_type: order.category_type,
        }"
        @click="serviceClick"
      >
        <svg-icon class="help-btn-icon" icon-class="help-service" />
        在线客服
      </van-button>
      <div
        class="text van-popup--safe-area-inset-bottom"
        v-ans="{ event_id: 'help_service_phone_click' }"
      >
        客服服务时间:
        <a href="tel:400-150-9669">400-150-9669</a>
        (8:00-20:00)
      </div>
    </section>
    <van-popup
      closeable
      position="bottom"
      round
      @click-overlay="close"
      @click-close-icon="close"
      v-model="selectHelp.visible"
      class="select-help-popup"
      safe-area-inset-bottom
    >
      <div class="title">{{ selectHelp.name }}问题</div>
      <ul class="help-list">
        <li
          class="help-list-item"
          v-for="(item, index) in selectHelp.helps"
          :key="item.id"
          v-ans="{ event_id: 'help_service_help_click', title: item.title }"
          @click="toHelpDetail(item, selectHelp.group)"
        >
          <div class="name ellipsis">{{ index + 1 }}. {{ item.title }}</div>
          <svg-icon class="arrow-right" icon-class="arrow2" />
        </li>
      </ul>
    </van-popup>
  </section>
</template>

<script>
import { getMemberInfo, getOrder } from "@/apis";
import { getHelpListV2 } from "@/apis/home";
import { useStore } from "@/components/use/base";
import { defaultUdesk } from "@/config";
import router from "@/router";
import {
  computed,
  defineComponent,
  onActivated,
  onDeactivated,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";
import pickBy from "lodash.pickby";
import Vue from "vue";
import helpOrder from "./helpOrder";
import { getProjectDivision } from "@/utils";

const vm = Vue.prototype;

let shouldDestroy = false;
export default defineComponent({
  name: "helpService",
  components: {
    helpOrder,
  },
  beforeRouteLeave(to, from, next) {
    // 只有进入到问题详情页面才缓存页面
    if (to.name !== "helpServiceDetail") {
      shouldDestroy = true;
    }
    next();
  },
  setup() {
    const order = ref({});
    const loading = ref(true);
    const helpOrderRef = ref();
    const selectHelp = reactive({
      group: {},
      name: "",
      visible: false,
      helps: [],
    });
    const helpCategorys = ref([]);
    const orders = ref([]);
    const store = useStore();
    const token = store.state.token;
    const getImageUrl = (item) => {
      const payInfo = JSON.parse(item.pay_info);
      let iconname = payInfo.detail_type || item.type;
      if (item.type === "atour") {
        iconname = item.type;
      }
      return `https://cdn.xiangshuheika.com/static/${store.getters.namespaceGroup}/${iconname}.png`;
    };
    const toService = async (order) => {
      helpOrderRef.value.close();
      vm.$copyText(order.order_number).then(() => {
        vm.$toast("订单号已复制");
      });
      let url = defaultUdesk;
      if (store.state.isMember) {
        const res = await getMemberInfo();
        url = res.data.service_url;
      }
      location.href = `${url}&hide_product=true&product_image=${encodeURIComponent(
        order.category_type === "benefit"
          ? getImageUrl(order)
          : "https://cdn.xiangshuheika.com/static/common/default.png"
      )}&product_订单号=${
        order.order_number
      }&product_send=false&product_title=${encodeURIComponent(order.name)}`;
    };

    const specialCategory = computed(() => {
      return helpCategorys.value.filter((item) => !item.is_default);
    });

    const defaultCategory = computed(() => {
      return (
        helpCategorys.value.find((item) => item.is_default) || { data: [] }
      );
    });

    onActivated(() => {
      if (shouldDestroy) {
        selectHelp.visible = false;
      }
      shouldDestroy = false;
    });

    onDeactivated(() => {
      // selectHelp.visible = false;
    });

    const close = () => {
      selectHelp.visible = false;
    };

    const toHelpDetail = (help, group) => {
      // selectHelp.visible = false;
      router.push({
        path: "/helpService/detail",
        query: pickBy(
          {
            id: help.id,
            group_id: group.id,
            order_number: order.value.order_number,
          },
          (v) => !!v
        ),
      });
    };

    const selectCategoryClick = (item) => {
      if (item.data.length === 1) {
        toHelpDetail(item.data[0], item);
        return;
      }
      selectHelp.group = item;
      selectHelp.name = item.group;
      selectHelp.helps = item.data;
      selectHelp.visible = true;
    };

    const serviceClick = () => {
      if (order.value.order_number) {
        // 二次确认选择的订单
        helpOrderRef.value.showServiceVisible();
      } else {
        // 直接进入在线客服
        location.href = defaultUdesk;
      }
    };

    onMounted(async () => {
      const project_name = getProjectDivision(store.state.namespace);
      const spining = vm.$loading();
      try {
        const helpRes = await getHelpListV2({
          namespace_name: store.state.namespace,
          project_name,
        });
        if (token) {
          const orderRes = await getOrder({
            source: "网络支付,api",
            created_day: 90,
            status: "success",
          });
          orders.value = orderRes.data.res_list;
          if (orders.value.length) {
            const helpServiceOrder = sessionStorage.getItem("helpServiceOrder");
            if (helpServiceOrder) {
              order.value = orders.value.find(
                (item) => item.order_number === helpServiceOrder
              );
            }
            if (!order.value || !order.value.order_number) {
              order.value =
                orders.value.find((item) => item.category_type === "vip") ||
                orders.value[0];
            }
          }
        }
        helpCategorys.value = helpRes.data;
      } finally {
        loading.value = false;
        spining.clear();
      }
    });
    return {
      close,
      order,
      orders,
      toService,
      selectHelp,
      specialCategory,
      defaultCategory,
      selectCategoryClick,
      token,
      loading,
      toHelpDetail,
      serviceClick,
      helpOrderRef,
    };
  },
});
</script>

<style lang="less" scoped>
.help-service {
  padding: 12px;
  background: #f4f5f7;
  min-height: 100vh;
  box-sizing: border-box;
}

.special-category {
  margin-bottom: 5px;
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4px;
    li {
      width: 50%;
      height: 61px;
      margin-bottom: 7px;
      padding: 0 4px;
      box-sizing: border-box;
    }
    .inner {
      background: #fff;
      border-radius: 10px;
      display: flex;
      align-items: center;
      padding: 10px 6px 12px;
      .title {
        font-size: 14px;
        color: #000;
        line-height: 20px;
        height: 20px;
        overflow: hidden;
        font-weight: bold;
        margin-bottom: 3px;
      }
      .sub-title {
        color: #666;
        font-size: 11px;
        line-height: 16px;
        height: 16px;
      }
      .title-icon {
        font-size: 12px;
        margin-left: 4px;
      }
    }
    .category-img {
      flex-basis: 34px;
      height: 34px;
      flex-shrink: 0;
      margin-right: 3px;
      margin-top: 4px;
    }
  }
}

.default-category {
  border-radius: 12px;
  background: #fff;
  overflow: hidden;
  .top {
    background: linear-gradient(168deg, rgba(#ff650d, 0.3) 0%, #ffffff 30%);
    padding: 13px 0 18px 16px;
    .title {
      line-height: 25px;
      font-weight: bold;
      font-size: 18px;
      width: max-content;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: -4px;
        height: 3px;
        width: 38px;
        border-radius: 3px;
        background: #ff5001;
        transform: translateX(-50%);
      }
    }
  }
}
.help-list {
  padding: 0 16px 8px;
  li {
    height: 50px;
    font-size: 14px;
    color: #333;
    &:not(:last-child) {
      border-bottom: 1px solid rgba(#f1f1f1, 0.7);
    }
    display: flex;
    align-items: center;
  }
  .arrow-right {
    margin-left: auto;
  }
}

.select-help-popup {
  .title {
    height: 52px;
    text-align: center;
    font-size: 15px;
    line-height: 52px;
  }
}
.height80 {
  height: 80px;
}
.footer {
  padding: 8px 12px;
  background: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  .footer-btn {
    height: 43px;
    background: linear-gradient(90deg, #ff650d 0%, #ff5f4a 100%);
    border-radius: 8px;
    color: #fff;

    margin-bottom: 6px;
    .help-btn-icon {
      font-size: 18px;
    }
  }
  .text {
    text-align: center;
    line-height: 16px;
    font-size: 11px;
    color: #999;
    a {
      color: #ff5001;
    }
  }
}
</style>
