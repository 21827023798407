<template>
  <main v-if="!loading" class="pay">
    <!-- <van-image
        lazy-load
        :src="spuDetail.display_img_url"
        class="sku-image"
      ></van-image> -->
    <div>
      <div class="pay-sku-inner">
        <div class="price">
          <span>¥</span>
          <span>{{ payInfo.price }}</span>
        </div>
        <div class="sku-name">{{ payInfo.item_name }}</div>
      </div>
    </div>
    <div class="pay-methods">
      <p class="pay-text">支付方式</p>
      <div class="pay-wrap">
        <div
          v-for="item in payList"
          :key="item.channel"
          class="pay-item"
          @click="selectedMethod = item"
          v-ans="{
            event_id: 'cashier_click_button2',
            payment_method_name: item.name,
            namespace,
          }"
        >
          <van-image class="pay-item-icon" :src="item.icon" />
          <div class="pay-detail">
            <div>
              <div class="pay-name">
                {{ item.name }}
                <span class="recommend" v-if="item.channel === 'alipay_wap'"
                  >推荐</span
                >
              </div>
              <span class="tips" v-if="item.tips">{{ item.tips }}</span>
            </div>
            <div
              class="pay-item-radio"
              :class="selectedMethod.channel === item.channel && checkBoxIcon"
            />
          </div>
        </div>
        <div
          class="more-payments"
          v-if="otherPayMethods.length"
          @click="showAllPayments"
        >
          <span class="more-payments-text">更多支付</span>
          <svg-icon class="down-icon" iconClass="down"></svg-icon>
        </div>
      </div>
    </div>
    <div class="pay-fixed">
      <van-button
        v-ans="{
          event_id: 'cashier_click_button1',
        }"
        @click="pay"
        class="pay-button"
        :class="payBtnColor"
        :loading="paying"
        block
        >立即支付</van-button
      >
    </div>
  </main>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { getPaymethod } from "@/apis/product";
import {
  agentConfig,
  baseApi,
  ENV_FLAG,
  getBaseUrl,
  IS_PROD,
  pay_api,
} from "@/config";
import { creatSign, decimal, storage } from "@/utils";
import pickBy from "lodash.pickby";
import { createPay, getBenefitInfo, getUserInfo } from "@/apis";
import { getHotelBook } from "@/apis/hotel";
import { querySkuStock, queryUserBenefitUse } from "@/apis/home";
import qs from "qs";
import ans from "@/utils/ans";
import pick from "lodash.pick";

export default {
  data() {
    return {
      payList: [],
      otherPayMethods: [],
      selectedMethod: {},
      spuDetail: {},
      price: "",
      loading: true,
      payInfo: this.$route.query,
      paying: false,
    };
  },
  async mounted() {
    const toast = this.$loading();
    try {
      await this.fetchPayment();
    } finally {
      toast.clear();
      this.loading = false;
    }
    const { item_code, price, sku_name, type } = this.$route.query;
    ans.pageView(this.$route.meta.page, {
      namespace: this.namespace,
      sku_name,
      source: this.source,
      benefit_type: type,
      item_code,
      sku_price: price,
    });
  },
  computed: {
    ...mapState([
      "namespace",
      "userInfo",
      "namespaceInfo",
      "namespace_group",
      "benefit_source",
      "return_url",
      "return_index_url",
      "source",
    ]),
    ...mapGetters(["payBtnColor", "getConfigValue"]),
    checkBoxIcon() {
      return this.getConfigValue("PAY_PAGE_IS_GOLD")
        ? "selected"
        : "selected-not-gold";
    },
  },
  methods: {
    // 获取支付相关信息--产品图片--价格

    // 获取支付方式
    async fetchPayment() {
      const pay_opts = {
        namespace: this.namespace,
        product_type: this.namespace.toUpperCase(),
        source: "default",
        is_vip: "F",
      };
      if (this.$getConfigValue("ALIPAY_ACTIVITY_SWITCH")) {
        pay_opts.item_code = this.$route.query.item_code;
      }
      const res = await getPaymethod(pay_opts);
      const methods = res.filter((r) => !r.channel.includes("xiaopai"));
      let pay_methods = methods.filter((r) => !r.is_fold);
      let otherPayMethods = methods.filter((r) => r.is_fold);

      this.payList = pay_methods;
      this.otherPayMethods = otherPayMethods;
      this.selectedMethod = pay_methods[0] || otherPayMethods[0] || {};
    },
    showAllPayments() {
      this.payList = this.payList.concat(this.otherPayMethods);
      this.otherPayMethods = [];
    },
    async pay() {
      await this.$buyBenefitValidate.startCheck({});
      const {
        type,
        order_number,
        item_name,
        passenger_number,
        remarks,
        hoter_order_params,
        activityName,
        session_id,
      } = this.$route.query;
      if (!this.payList.length) {
        this.$toast("支付渠道不存在");
        return;
      }
      const MALICIOUS_USERS = this.$getConfigValue("MALICIOUS_USERS") + "";
      if (MALICIOUS_USERS) {
        const users = MALICIOUS_USERS.split(",").filter(Boolean);
        if (users.includes(this.userInfo.id + "")) {
          this.$alert({
            title: "温馨提示",
            message: this.$getConfigValue("MALICIOUS_USER_ALERT_TEXT"),
          });
          return;
        }
      }
      //判断该用户是否是黑名单用户 并且 是否可以激活权益
      const userInfo = this.userInfo;
      if (
        Object.keys(userInfo.bl_attr).length > 0 &&
        userInfo.bl_attr.activate_benefit_limit
      ) {
        this.$toast("你的账号存在异常行为，暂时无法操作");
        return;
      }
      this.paying = true;
      try {
        // 秒杀活动不判断点数
        if (
          !activityName &&
          !session_id &&
          !this.$getConfigValue("HOTEL_SINGLE_BENEFIT")
        ) {
          const info = await getBenefitInfo({
            benefit_type: type,
          });
          // 判断当月是否已使用过权益
          if (info.data.amount_available === 0 && info.data.amount_reserved) {
            this.$alert({
              message: "您本期已用过此权益，请下期再来",
              confirmButtonText: "好的",
            });
            return;
          }

          // 可用次数
          if (info.data.amount_available === 0 && !info.data.amount_reserved) {
            this.$alert({
              message: "剩余可用次数不足，可前往订单查看已获取的权益或券码。",
              confirmButtonText: "去查看",
            }).then(() => {
              this.$router.push("/order");
            });
            return;
          }
        } else {
          // // 秒杀限制
          // await seckillActivity({
          //   activity: activityName,
          //   item_code: this.payInfo.item_code,
          //   session_id,
          // });
        }

        // 限制用户当日只能使用一次权益
        if (this.$getConfigValue("LIMIT_USER_BENEFIT")) {
          const res = await queryUserBenefitUse();
          if (res.data === "used") {
            this.$toast("每天仅可使用1项权益，你今天已用过，请明天再来");
            return;
          }
        }

        // 查询库存
        // try {
        //   const stock = await querySkuStock({
        //     item_code: this.selectSku.item_code,
        //     user_id: this.userInfo.id,
        //     hideMsg: true,
        //   });
        //   if (
        //     stock.data.daily_stocks <= 0 ||
        //     stock.data.partner_stocks <= 0 ||
        //     stock.data.saleable_stocks <= 0 ||
        //     stock.data.user_daily_stocks <= 0
        //   ) {
        //     this.$toast("该商品暂时无货，请改天再来或咨询客服");
        //     return;
        //   }
        // } catch (e) {}

        let opts = {
          platform: "extra",
          benefit_source: this.benefit_source || "会员权益",
          item_code: decodeURIComponent(this.payInfo.item_code),
          channel: this.selectedMethod.channel,
          order_desc: decodeURIComponent(this.payInfo.sku_name),
          order_source: this.userInfo.card_type_cn,
          enable_auto_jump: 1,
          partner_jump_url: encodeURIComponent(
            this.return_url ||
              (window.location.origin + window.location.pathname).replace(
                /\/$/,
                ""
              ) + `/pages/pay_result/pay_result?category_type=benefit`
          ),
          partner_url: encodeURIComponent(
            this.return_url ||
              (window.location.origin + window.location.pathname).replace(
                /\/$/,
                ""
              ) + `/pages/pay_result/pay_result?category_type=benefit`
          ),
          order_name: decodeURIComponent(item_name),
          coupon_id: this.payInfo.coupon_id,
          envflag: ENV_FLAG(this.namespace),
          is_prod: IS_PROD,
          namespace: this.namespace,
          namespace_group: this.namespace_group,
          timestamp: this.payInfo.timestamp || new Date().getTime(),
          project_url: getBaseUrl(this.namespace),
          user_token: storage.getItem("token"),
          detail_type: this.payInfo.detail_type,
          agent_id: agentConfig("common"),
          out_order_id:
            this.payInfo.out_order_id ||
            `OAK${navigator.productSub}${new Date().getTime()}`,
          charge_game_region: this.payInfo.charge_game_region,
          top_up_account: this.payInfo.account || this.payInfo.phone_number,
          source: this.payInfo.source,
          order_number: this.payInfo.order_number,
          activity: activityName,
          session_id,
          openid: storage.getItem("openid"),
          return_index_url: encodeURIComponent(this.return_index_url),
          amount: this.payInfo.price,
          park_name: this.payInfo.park_name,
          ticket_cnt: this.payInfo.ticket_cnt,
          ticket_mobile: this.payInfo.ticket_mobile,
          ticket_name: this.payInfo.ticket_name,
          user: this.payInfo.user,
          id_card: this.payInfo.id_card,
          park_id: this.payInfo.park_id,
          checkin_date: this.payInfo.checkin_date,
          recharge_account_type: this.payInfo.recharge_account_type,
        };

        if (type === "buffet") {
          opts.biz_params_info = this.payInfo.biz_params_info;
          opts.item_code = encodeURIComponent(opts.item_code);
        }
        if (opts.item_code.includes("#")) {
          opts.item_code = encodeURIComponent(opts.item_code);
        }
        if ((type === "hotel" || type === "ehotel") && !order_number) {
          opts.order_source = decodeURIComponent(
            this.$getConfigValue("HOTEL_SMS_NAME")
          );
          let temp = {};
          try {
            temp = JSON.parse(decodeURIComponent(hoter_order_params));
          } catch (e) {
            //
          }
          const [hotelData] = await Promise.all([getHotelBook(temp)]);
          const orderInfo = hotelData.data;
          if (passenger_number) {
            opts.passenger_number = passenger_number;
          }
          if (remarks) {
            opts.remarks = decodeURIComponent(remarks);
          }
          // 针对经济型酒店，在namespace后面拼接 affordable
          if (type === "ehotel") {
            opts.namespace = opts.namespace + "-affordable";
          }
          opts.order_name = decodeURIComponent(item_name);
          opts.pay_method = orderInfo.pay_method;
          opts.hotel_name = orderInfo.hotel_name;
          opts.checkin_date = orderInfo.checkin_date;
          opts.checkout_date = orderInfo.checkout_date;
          opts.stay_days = orderInfo.stay_days;
          opts.room_cnt = orderInfo.room_cnt;
          opts.room_name = orderInfo.room_name;
          opts.total_origin_price = orderInfo.total_origin_price;
          opts.total_price = orderInfo.total_price;
          opts.hotel_id = temp.hotel_id;
          opts.room_id = temp.room_id;
          opts.total_price_difference =
            Number(orderInfo.total_origin_price) -
            Number(orderInfo.total_price);
          opts.deposit = orderInfo.total_price;
          opts.breakfast_type = decodeURIComponent(orderInfo.breakfast);
          opts.user = this.payInfo.cn_name;
          opts.phone = this.payInfo.phone_number;
        }
        opts = pickBy(opts, (v) => !!v || v === 0);
        const useragent = navigator.userAgent;
        if (
          useragent.indexOf("miniProgram") !== -1 &&
          this.$getConfigValue("USE_WECHAT_MINI_PAY")
        ) {
          // 获取下单参数，跳转到微信小程序进行支付
          // 微信这块item_code有问题
          opts.item_code = decodeURIComponent(opts.item_code);
          const res = await createPay(opts);
          if (res.data.package === "prepay_id=None") {
            this.$toast("生成微信预支付订单失败");
            return;
          }
          const params = res.data;
          params.backUrl =
            location.href.split("#")[0] +
            `#/pages/pay_result/pay_result?order_number=${params.order_number}&order_source=miniapp&category_type=benefit`;
          wx.miniProgram.redirectTo({
            url: `${this.$getConfigValue(
              "WECHAT_MIN_PAY_URL"
            )}?payParam=${encodeURIComponent(JSON.stringify(params))}`,
          });
          return;
        }
        if (/^XY03|XY06|XY12$/.test(opts.item_code)) {
          opts.unique_id = this.payInfo.unique_id;
          opts.sign = this.payInfo.sign;
        } else {
          opts.sign = encodeURIComponent(
            creatSign(
              "common",
              pickBy(
                pick(
                  {
                    ...opts,
                    item_code: decodeURIComponent(opts.item_code),
                  },
                  [
                    "platform",
                    "item_code",
                    "channel",
                    "partner_jump_url",
                    "agent_id",
                    "timestamp",
                    "user_token",
                    "benefit_source",
                    "activity",
                  ]
                ),
                (v) => v !== null && v !== undefined
              )
            )
          );
        }
        const paramsData = qs.stringify(opts, { encode: false });
        const origin = this.$getConfigValue("PAY_API_PATH")
          ? baseApi.common + this.$getConfigValue("PAY_API_PATH")
          : pay_api;
        location.href = `${origin}?${paramsData}`;
      } finally {
        this.paying = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.pay-sku-inner {
  margin-top: 37px;
  margin-bottom: 38px;
  .price {
    font-size: 34px;
    font-weight: 600;
    color: #000000;
    line-height: 48px;
    text-align: center;
    > span:nth-child(1) {
      font-size: 23px;
      line-height: 32px;
      vertical-align: text-top;
      margin-right: 4px;
    }
  }
}

.pay-wrap {
  background: #ffffff;
  border-radius: 10px;
}

.sku-name {
  line-height: 17px;
  font-size: 12px;
  color: #666666;
  text-align: center;
}
.pay {
  padding: 0 15px;
  background: #f5f5f5;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.pay-sku {
  // display: flex;
  padding-top: 16px;
  padding-bottom: 13px;
  border-bottom: 1px solid #ebecf1;
  margin-bottom: 20px;
}
.sku-image {
  flex-basis: 104px;
  height: 80px;
  margin-right: 10px;
  flex-shrink: 0;
  border-radius: 4px;
  overflow: hidden;
}
.sku-info {
  display: flex;
  align-items: center;
  // flex-direction: column;
  justify-content: space-between;
}

.sku-price {
  font-size: 20px;
  margin-left: 20px;
  flex-shrink: 0;
  .sub {
    font-size: @font-xs;
  }
}
.sku-facevalue {
  font-size: @font-sm;
  margin-left: 10px;
  color: @text-gray2-color;
  font-weight: normal;
  text-decoration: line-through;
}

.pay-item {
  overflow: hidden;
  display: flex;
  padding: 16px 20px;
  position: relative;
  &:not(:last-child) {
    &::after {
      content: "";
      position: absolute;
      left: 51px;
      bottom: 0px;
      width: 100%;
      height: 1px;
      background: #f5f5f5;
    }
  }

  .pay-detail {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 9px;
    .pay-name {
      font-size: 14px;
      color: #333333;
      line-height: 20px;
      margin-bottom: 3px;
      display: flex;
      align-items: center;
    }
  }
}

.pay-item-icon {
  width: 22px;
  height: 22px;
  flex-shrink: 0;
}

.pay-text {
  font-size: 11px;
  font-weight: 400;
  color: #666;
  line-height: 16px;
  margin-bottom: 6px;
}

.pay-fixed {
  bottom: 21px;
  position: fixed;
  left: 15px;
  right: 15px;
}
.pay-button {
  height: 50px;
  font-size: 17px;
  border: none;
  border-radius: 100px;
  font-family: PingFangSC-Medium, PingFang SC;
}
.tips {
  border-radius: 3px;
  padding: 0 3px;
  border: 1px solid rgba(246, 54, 54, 0.4);
  font-size: 9px;
  color: #f63636;
  line-height: 13px;
}

.down-icon {
  font-size: 12px;
  margin-left: 3px;
}

.pay-item-radio {
  height: 20px;
  width: 20px;
  margin-left: auto;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  position: relative;
  border-radius: 50%;
  &.selected {
    background: url("../../assets/images/pay-checkbox.png");
    background-size: contain;
    border: none;
  }
  &.selected-not-gold {
    background: url("../../assets/images/pay-checkbox-not-gold.png");
    background-size: contain;
    border: none;
  }
}

.more-payments {
  padding: 13px 0 16px 51px;
}
.more-payments-text {
  color: @text-light-color;
  font-size: 11px;
  line-height: 16px;
}

.recommend {
  background: #ff603b;
  border-radius: 8px 8px 8px 0px;
  font-size: 9px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  line-height: 13px;
  padding: 1px 6px 1px 5px;
  margin-left: 3px;
}
</style>