<template>
  <div class="main">
    <div v-if="userInfo.card_status === 'expired'" class="card-past">
      <van-image :src="require('../../../assets/images/yh-empty.png')" />
      <p>您的权益已过期</p>
    </div>
    <div v-else-if="userInfo.card_status === 'refund'" class="card-past">
      <van-image :src="require('../../../assets/images/yh-empty.png')" />
      <p>您的权益已退款</p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

import { useStore } from "@/components/use/base";

export default defineComponent({
  setup() {
    const store = useStore();

    const userInfo = store.state.userInfo;

    return {
      userInfo,
    };
  },
});
</script>


<style lang="less" scoped>
.main {
  margin: 0 14px;
  height: 331px;
  background: #fff;
  border-radius: 13px;
  position: relative;
}

.card-past {
  border-radius: 5px;
  overflow: hidden;
  p {
    text-align: center;
    line-height: 1.5;
    margin-top: 17px;
    font-size: 16px;
    margin-top: 17px;
  }
  /deep/ .van-image {
    width: 80px;
    height: 80px;
    margin: 56px auto 14px;
  }
}
</style>