<template>
  <div class="wrap">
    <div class="header">
      <div class="contacts" @click="toService">
        <van-icon name="phone" class="icon" />联系客服
      </div>
    </div>

    <div class="main">
      <div class="code-redeem">
        <div class="code-inp-wrap">
          <input
            class="code-inp"
            placeholder="请输入兑换码 "
            v-model="inpValue"
          />
        </div>
        <div class="redeem-btn" @click="handleDraw">立即兑换</div>
      </div>
    </div>

    <div class="content">
      <div v-if="!benefitList.length" class="empty-wrap">
        <p>空空如也，快去兑换吧！</p>
      </div>
      <div class="product" v-else>
        <div class="tip" />
        <div>
          <p class="title">{{ cardTypeCn }}</p>
          <p class="time">
            会员有效期 {{ createTime | formatDate("YYYY.MM.DD") }}-{{
              endTime | formatDate("YYYY.MM.DD")
            }}
          </p>
        </div>
        <div @click="toUse" class="use-btn">去使用</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  ref,
  computed,
} from "@vue/composition-api";
import Vue from "vue";

import { goCashing } from "@/apis/home";
import { useRouter, useStore } from "@/components/use/base";
import { getUserBenefit } from "@/apis/home";
import LoginDialog from "@/components/loginDialog";

const vm = Vue.prototype;

export default defineComponent({
  setup() {
    const router = useRouter();
    const store = useStore();
    const benefitList = ref([]);
    const inpValue = ref("");

    const isLogin = computed(() => !!store.state.token);
    const isMember = computed(() => store.state.isMember);
    const createTime = computed(() => store.state.userInfo.t_vip_created);
    const endTime = computed(() => store.state.userInfo?.card_expiry_date?.[0]);
    const cardTypeCn = computed(() => store.state.userInfo.card_type_cn);

    const initData = async () => {
      const { data } = await getUserBenefit();
      data?.benefit_list?.length
        ? (benefitList.value = data.benefit_list)
        : (benefitList.value = []);
    };

    const handleDraw = async () => {
      if (!inpValue.value) {
        vm.$toast("请输入兑换码");
        return;
      }
      if (!isLogin.value) {
        vm.$toast("请先登录");
        await login();
      }
      const loading = vm.$loading();
      try {
        await goCashing({
          cashing_code: inpValue.value,
        });
        store.dispatch("fetchUserInfo");
        initData();
        inpValue.value = "";
      } catch (error) {
        console.log(error);
      } finally {
        loading.clear();
      }
    };

    const toUse = () => {
      router.push("/dc-member");
    };

    const login = async () => {
      await LoginDialog.show({
        title: "手机号登录后使用",
        className: "dc-login",
      });
      isMember.value && (await initData());
    };

    const toService = () => {
      router.push("/service");
    };

    onMounted(() => {
      !isLogin.value ? login() : initData();
    });

    return {
      toService,
      handleDraw,
      benefitList,
      createTime,
      endTime,
      toUse,
      inpValue,
      cardTypeCn,
    };
  },
});
</script>

<style lang="less" scoped>
.wrap {
  background: #ecf9ff;
  min-height: 100vh;
  padding-bottom: 34px;
}
.header {
  background: url(https://common-h5-dev.xiangshuheika.com/static/upload/20240905b3ccaf052b19a.png);
  background-repeat: no-repeat;
  background-size: 100%;
  height: 385px;
  position: relative;
  .contacts {
    position: absolute;
    display: flex;
    align-items: center;
    background: linear-gradient(270deg, #f39378 0%, #e35a43 100%);
    border-radius: 12px 0px 0px 12px;
    right: 0;
    top: 15px;
    padding: 1px 8px;

    font-size: 11px;
    color: #ffffff;
    line-height: 23px;
    text-align: center;
    .icon {
      margin-right: 4px;
    }
  }
}

.main {
  margin: 0 12px;
  margin-bottom: 13px;
  background: url(https://common-h5-dev.xiangshuheika.com/static/upload/2024090521ab4c9c8a8ae.png);
  background-repeat: no-repeat;
  background-size: 100%;
  height: 223px;
  position: relative;

  .code-redeem {
    position: absolute;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 16px;
    top: 85px;
    left: 50%;
    transform: translateX(-50%);

    .code-inp-wrap {
      margin: 0 auto;
      width: 294px;
      height: 44px;
      border-radius: 22px;
      border: 1px solid #b9b9b9;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .code-inp {
      border: none;
      width: 240px;
      &::placeholder {
        text-align: left;
        color: #999999;
        font-size: 14px;
      }
    }
    .redeem-btn {
      margin: 0 auto;
      margin-top: 16px;
      width: 294px;
      height: 44px;
      background: linear-gradient(270deg, #f39378 0%, #e35a43 100%);
      border-radius: 22px;
      font-family: AppleSystemUIFont;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      line-height: 44px;
      text-align: center;
    }
  }
}

.content {
  margin: 0 12px;
  background: url(https://common-h5-dev.xiangshuheika.com/static/upload/20240905b967ab09e9dc4.png);
  background-repeat: no-repeat;
  background-size: 100%;
  height: 183px;
  position: relative;
  overflow: hidden;
}

.empty-wrap {
  margin: 0 16px;
  margin-top: 105px;
  box-sizing: border-box;

  > p {
    font-weight: 400;
    font-size: 13px;
    color: #999999;
    line-height: 18px;
    text-align: center;
  }
}

.product {
  background: url(https://common-h5-dev.xiangshuheika.com/static/upload/20240905e4329db669c57.png);
  background-repeat: no-repeat;
  background-size: 100%;
  height: 83px;
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 72px;
  width: 316px;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px 0 27px;
  .title {
    font-weight: 600;
    font-size: 13px;
    color: #333333;
    line-height: 18px;
  }
  .time {
    font-weight: 400;
    font-size: 10px;
    color: #666666;
    line-height: 14px;
    margin-top: 3px;
  }
  .use-btn {
    width: 60px;
    height: 26px;
    background: #fffefa;
    border-radius: 13px;
    text-align: center;
    font-weight: 500;
    font-size: 11px;
    color: #dc4f50;
    line-height: 26px;
  }
  .tip {
    background: url(https://common-h5-dev.xiangshuheika.com/static/upload/20240905d4b80bd303de4.png);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 62px;
    height: 23px;
    position: absolute;
    left: -7px;
    top: -4px;
  }
}
</style>

<style lang="less">
.dc-login {
  .van-button {
    background: #e35a43;
    color: #fff;
  }
}
</style>
