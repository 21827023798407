<template>
  <van-dialog :show-confirm-button="false" v-close="close" :value="value">
    <div class="mileage">
      <div class="title">选择航司兑换里程</div>
      <van-image
        class="haihang"
        :src="require('../../assets/images/haihang.png')"
      />
      <div class="form-item">
        <div class="form-item-label">兑换里程数</div>
        <div class="form-item-value">1000</div>
      </div>
      <div class="form-item">
        <div class="form-item-label">航司会员号</div>
        <div class="form-item-value">
          <input
            placeholder="若填错则里程不予退回"
            v-model.trim="card_number"
          />
        </div>
      </div>
      <div class="form-item">
        <div class="form-item-label">姓名</div>
        <div class="form-item-value">{{ userInfo.cn_name }}</div>
      </div>
      <div class="form-item">
        <div class="form-item-label">手机号</div>
        <div class="form-item-value">{{ userInfo.user_phone }}</div>
      </div>
      <van-button
        :disabled="loading || !card_number"
        @click="submit"
        class="form-btn"
        type="info"
        block
        round
        >立即兑换</van-button
      >
    </div>
  </van-dialog>
</template>

<script>
import { airlines } from "@/apis";
import { mapState } from "vuex";
import { activateBenefit } from "@/apis/home";
export default {
  props: ["value", "selectSku"],
  data() {
    return {
      card_number: "",
      loading: false,
    };
  },
  computed: {
    ...mapState(["userInfo", "userInfo"]),
  },
  methods: {
    async submit() {
      if (!this.card_number) {
        this.$toast("请输入航司会员号");
        return;
      }
      const loading = this.$loading();
      this.loading = true;
      try {
        const res = await airlines();
        await activateBenefit({
          benefit_source: "会员权益",
          benefit_type: this.$route.query.benefit_type,
          airmile_cost: 1000,
          airline_account: this.card_number,
          airline: res.data.uid,
          is_zero_pay: true,
          order_name: this.selectSku.item_name,
          item_code: this.selectSku.item_code,
          top_up_type: this.selectSku.top_up_type,
          detail_type: this.selectSku.item_name,
          top_up_account: this.userInfo.user_phone,
        });
        this.$toast("里程兑换成功");
        this.close();
        this.$emit("refresh");
        this.loading = false;
        loading.clear();
      } catch (e) {
        this.loading = false;
        loading.clear();
      }
    },
    close() {
      this.card_number = "";
      this.$emit("update:value", false);
    },
  },
};
</script>

<style lang="less" scoped>
.mileage {
  padding: 20px 15px 20px;
}
.title {
  line-height: 25px;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
}
.haihang {
  width: 48px;
  height: 48px;
  display: block;
  margin: 0 auto 10px;
}

.form-item {
  display: flex;
  height: 46px;
  line-height: 22px;
  align-items: flex-end;
}
.form-item-label {
  flex: 1;
  margin-right: 8px;
  font-size: 14px;
  text-align: right;
}
.form-item-value {
  flex: 2;
  color: #222;
  font-size: 16px;
  padding-left: 5px;
  border-bottom: 1px solid #e1e2e6;
  /deep/ input {
    border: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    background-color: transparent;
    line-height: inherit;
    box-sizing: border-box;
    &:focus {
      outline: none;
    }
    &::placeholder {
      font-size: 13px;
    }
  }
}

.form-btn {
  margin-top: 30px;
}
</style>